import React, {useEffect, useState} from "react";
import {Route, Routes, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@mui/styles";
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Stack,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {
    Bookmarks,
    Cancel,
    Category as CategoryIcon,
    Delete,
    DesignServices,
    LibraryBooks,
    Mic,
    QuestionMark,
    Save,
    Sell,
    TextFields,
    Visibility
} from "@mui/icons-material";
import {DataStore, Storage} from "aws-amplify";
import {Author, Book, Family, Category, BookContent, Draft, SubCategory} from "../../../models";
import {tdb} from "../../Services/translateJSON";
import {BookImgModal, BookInfo} from "../BookCommon";
import {BookStatusChip} from "../../Misc/Status";
import BookBookEpub from "./BookBookEpub";
import {BookBookContents} from "./BookBookContents";
import {BookBookContent} from "./BookBookContent";
import PurchaseList from "../../Purchase/PurchaseList";
import moment from "moment";
import RatingList from "../../Rating/RatingList";
import LikeList from "../../Like/LikeList";
import {chooseCorrectKey} from "../../Services/correctPath";

export default function BookBook() {
    const {bookId} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    
    // GET BOOK
    const [book, setBook] = useState("");
    const fetchBook = async () => setBook(await DataStore.query(Book, bookId));
    
    const [contents, setContents] = useState([]);
    const fetchContents = async () => setContents((await DataStore.query(BookContent))
        .filter(c=>c.book&&c.book.id===bookId)
        .sort((a,b)=>a.order===b.order?0:(a.order>b.order?1:-1))
    )
    
    useEffect(()=>{
        fetchBook()
        fetchContents()
        const _bookSubscribe = DataStore.observe(Book, bookId).subscribe(msg=> fetchBook())
        return () => _bookSubscribe.unsubscribe();
        
    }, [bookId]);
    
    // MODALS
    const [priceEditMode, setPriceEditMode] = useState(false);
    const [coverViewMode, setCoverViewMode] = useState(false);
    
    // TYPE and STATUS DEPENDENCIES
    const [avatarType, setAvatarType] = useState();
    
    // Author
    const [author, setAuthor] = useState("");
    const fetchAuthor = async () => {setAuthor(await DataStore.query(Author, book.author.id))}
    const [avatarURL, setAvatarURL] = useState();
    const fetchAvatarURL = () => {
        Storage.get(chooseCorrectKey(author.avatarKeys.sm, {level: 'protected'}), {
            level: 'protected',
            identityId: author.avatarKeys.identity
        })
            .then(_image => setAvatarURL(_image))
    }
    useEffect(()=>{if (author && author.avatarKeys && author.avatarKeys.sm)fetchAvatarURL()}, [author])
    
    // Family & Category
    const [family, setFamily] = useState();
    const [category, setCategory] = useState();
    const [subcategory, setSubcategory] = useState();
    const fetchFamily = async () => setFamily(await DataStore.query(Family, book.family.id))
    const fetchCategory = async () => setCategory(await DataStore.query(Category, book.category.id))
    const fetchSubcategory = async () => setSubcategory(await DataStore.query(SubCategory, book.subCategory.id))
    
    
    // cover image
    const [imageURL, setImageURL] = useState("");
    const fetchImageURL = () => {
        Storage
            .get(chooseCorrectKey(book.coverKeys.md, {level: 'protected'}), {
                level: 'protected',
                identityId: book.coverKeys.identity
            })
            .then(_image => setImageURL(_image))
    }
    
    // jum to book
    const navigateToDraft = (draft) => {
        navigate(`/books/drafts/${draft.id}`)
    }
    
    const [active, setActive] = useState();
    useEffect(()=>{
        if (book) {
            switch (book.bookType) {
                case "Audio": setAvatarType(<Mic fontSize={"large"} />);break;
                case "Epub": setAvatarType(<LibraryBooks fontSize={"large"} />);break;
                case "Text": setAvatarType(<TextFields fontSize={"large"} />);break;
                default: setAvatarType(<QuestionMark fontSize={"large"} />);break
            }
        }
        if(book && book.category)fetchCategory()
        if(book && book.subCategory)fetchSubcategory()
        if(book && book.family)fetchFamily()
        if(book && book.author)fetchAuthor()
        if(book && book.coverKeys && book.coverKeys.md) fetchImageURL();
        setActive(book.status === "Published")
    }, [book])
    
    const handleToggleStatus = () => {
        DataStore.save(Book.copyOf(book, updater=>{
            updater.status = book.status === "Published" ? "Unpublished" : "Published"
        }))
    }
    
    const cols = {
        lg:3,
        md:6,
        xs:12,
    }
    
    const [deleteMode, setDeleteMode] = useState();
    const handleDelete = async () => {
        await DataStore.save(Draft.copyOf(book.draft, updater=>{
            updater.status = "Draft"
        }));
        await DataStore.delete(book);
        await Promise.all(contents.map(content=>{
            return DataStore.delete(content)
        }))
        navigateToDraft(book.draft)
    }
    
    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction={"row"} spacing={2} justifyItems={"center"}>
                    <Box pt={1}>
                        <Avatar sx={{height:60, width:60}}>
                            {avatarType}
                        </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                        <Stack direction={"column"}>
                            <Typography variant={"h4"}>
                                {book && book.title}
                            </Typography>
                            <Typography variant={"subtitle1"}>
                                {book && book.description}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        {book && book.bookType}
                    </Box>
                </Stack>
            </Grid>
            {/* STATS */}
            <Grid item xs={12}>
                <Card elevation={4}>
                    <CardContent sx={{pb: 0}}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                {`ventes: ${book.purchasedCount || "Non connu"}`}
                            </Grid>
                            <Grid item xs={3}>
                                {`montant: ${book.purchasedAmount ? book.purchasedAmount+"Kau" : "Non connu"}`}
                            </Grid>
                            <Grid item xs={3}>
                                {`likes: ${book.likesCount || "Non connu"}`}
                            </Grid>
                            <Grid item xs={3}>
                                {`Ratings: ${book.rating || "Non connu"} ${book.ratingCount ? "("+book.ratingCount+")" : ""}`}
                            </Grid>
                        </Grid>
                    </CardContent >
                </Card>
            </Grid>
            {/* INFOS */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.fields.infos")}
                        action={<IconButton onClick={()=>setDeleteMode(true)}><Delete /></IconButton> }
                    />
                    {deleteMode && <Dialog open={true} onClose={()=>setDeleteMode(false)}>
                        <DialogTitle>{t('book.operations.delete-dialog')}</DialogTitle>
                        <DialogContent>
                            {t('book.operations.delete-helper')}
                        </DialogContent>
                        <DialogActions>
                            <Button variant={"contained"} color={"error"} onClick={()=>setDeleteMode(false)}>{t('book.operations.delete-cancel')}</Button>
                            <Button variant={"contained"} color={"error"} onClick={handleDelete}>{t('book.operations.delete-perform')}</Button>
                        </DialogActions>
                    </Dialog> }
                    <CardMedia className={classes.subCardContent}>
                        <List disablePadding dense>
                            <BookInfo
                                avatar={<Avatar src={avatarURL} />}
                                primary={"Auteur"}
                                secondary={`${author && author.firstName} ${author && author.name}`}
                                danger={!author}
                            />
                            <BookInfo
                                avatar={<CategoryIcon />}
                                primary={`${family && tdb(family.title)}`}
                                secondary={`${category && tdb(category.title)}${subcategory && ' / '+tdb(subcategory.title)}`}
                            />
                            <BookInfo
                                avatar={<Sell />}
                                primary={"Prix"}
                                secondary={`${book && book.price} KAU`}
                                danger={book && !book.price}
                                edit={()=>setPriceEditMode(true)}
                                editable={true}
                            />
                            <BookInfo
                                avatar={<Bookmarks />}
                                primary={`ISBN`}
                                secondary={`${book && book.isbn}`}
                                danger={book && !book.isbn}
                            />
                        </List>
                    </CardMedia>
                </Card>
            </Grid>
            {/* SUMMARY */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.fields.summary")}
                    />
                    <CardContent className={classes.subCardContent}>
                        {book && !!book.summary && <Typography variant={"body2"}>{book.summary}</Typography>}
                        {book &&  !book.summary && <Alert severity={"error"}>Résumé manquant</Alert>}
                    </CardContent>
                </Card>
            </Grid>
            {/* COVER */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.cover.card")}
                        action={
                            <Stack direction="row" spacing={0}>
                                <IconButton disabled={!imageURL} onClick={()=>setCoverViewMode(true)}><Visibility /></IconButton>
                            </Stack>
                        }
                    />
                    {imageURL
                        ? <CardMedia
                            className={classes.subCardContent}
                            component="img"
                            height="140"
                            image={imageURL}
                        />
                        : <CardContent className={classes.subCardContent}>
                            <Alert severity={"error"} square>{t('Image manquante')}</Alert>
                        </CardContent>
                    }
                
                </Card>
            </Grid>
            {/* STATUS */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.status.card")}
                        action={<Switch
                            checked={active}
                            onChange={handleToggleStatus}
                        />}
                    />
                    <CardContent className={classes.subCardContent}>
                        <BookStatusChip status={book.status} active={true} />
                        <List dense>
                            <ListItem>
                                <ListItemText>
                                    {`Publié le ${moment(book._created).format('DD/MM/YYYY hh:mm')}`}
                                </ListItemText>
                            </ListItem>
                            {book._modified !== book._created && <ListItem>
                                <ListItemText>
                                    {`Mis à jour le ${moment(book._modified).format('DD/MM/YYYY hh:mm')}`}
                                </ListItemText>
                            </ListItem>}
                            {book._version > 1 && <ListItem>
                                <ListItemText>
                                    {`Version ${book._version}`}
                                </ListItemText>
                            </ListItem>}
                            {book.draft && <>
                                <ListItem>
                                    <ListItemText>
                                        {t('Document de travail :')}
                                    </ListItemText>
                                </ListItem>
                                <ListItem button onClick={()=>navigateToDraft(book.draft)}>
                                    <ListItemIcon sx={{width: 40}}>
                                        <DesignServices/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {book.draft.title}
                                    </ListItemText>
                                </ListItem>
                            </>}
                        </List>
                    </CardContent>
                </Card>
            </Grid>
            {/* EPUB */}
            {book && book.bookType === "Epub" && <Grid item xs={12}>
                <BookBookEpub bookId={bookId} />
            </Grid>}
            {/* CONTENT */}
            {book && book.bookType !== "Epub" && <Routes>
                <Route path={""} element={<BookBookContentsAlone bookId={bookId} />} />
                <Route path={":contentId"} element={<BookBookContentsAndContent />} />
            </Routes>}
            {/* PURCHASE */}
            <Grid item xs={12}>
                <PurchaseList bookId={bookId} />
            </Grid>
            {/* RATING */}
            <Grid item xs={12}>
                <RatingList bookId={bookId} />
            </Grid>
            {/* LIKE */}
            <Grid item xs={12}>
                <LikeList bookId={bookId} />
            </Grid>
        </Grid>
        {priceEditMode      && <BookBookEditPrice     onClose={()=>setPriceEditMode(false)} bookId={bookId}  />}
        {coverViewMode      && <BookImgModal          onClose={()=>setCoverViewMode(false)} open={coverViewMode} urlImg={imageURL} />}
    </Box>
}

function BookBookContentsAlone({bookId}) {
    return <Grid item xs={12}>
        <BookBookContents bookId={bookId} full={true} />
    </Grid>
}

function BookBookContentsAndContent() {
    const {bookId, contentId}    = useParams();
    return <>
        <Grid item xs={12} md={6}>
            <BookBookContents bookId={bookId} full={false} />
        </Grid>
        <Grid item xs={12} md={6}>
            <BookBookContent bookId={bookId} contentId={contentId} />
        </Grid>
    </>
}

// PRICE
export function BookBookEditPrice({onClose, bookId}) {
    const {t} = useTranslation();
    const classes = useStyles();
    
    const [book, setBook] = useState();
    const fetchBook = async () => setBook((await DataStore.query(Book, bookId)));
    useEffect(()=>{if (bookId)fetchBook()}, [bookId]);
    
    const [price, setPrice] = useState("");
    useEffect(()=>{if (book)setPrice(book.price)}, [book])
    
    const handleSave = async () => {
        DataStore.save(Book.copyOf(book, updater => {
            updater.price = parseFloat(price);
        })).then(result=>onClose());
    }
    
    return <Dialog open={true} maxWidth={"md"} fullWidth onClose={onClose}>
        <DialogTitle>{t('book.form.price')}</DialogTitle>
        <DialogContent>
            <Grid container spacing={2} sx={{pt: 2}}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        value={price}
                        variant={"outlined"}
                        label={t('book.form.price')}
                        onChange={event=>setPrice(event.target.value)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                variant={"contained"}
                color={"secondary"}
                startIcon={<Cancel />}
                onClick={onClose}
            >
                {t('generic.cancel')}
            </Button>
            <Button
                variant={"contained"}
                startIcon={<Save />}
                onClick={handleSave}
            >
                {t('book.operations.update-perform')}
            </Button>
        </DialogActions>
    </Dialog>
}

const useStyles = makeStyles((theme) => ({
    rightAlign: {
        marginLeft: 'auto',
    },
    subCardHeader: {
        height: 66
    },
    subCardContent: {
        height: 250,
        overflow: 'auto'
    },
    marginDivider:{
        marginBottom:10,
        marginTop:10
    }
}))

