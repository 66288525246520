import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Box, Card, CardContent, CardHeader, Chip, IconButton, Link, Tooltip, Typography} from "@mui/material";
import {MenuBook, OpenInNew} from "@mui/icons-material";
import {DataStore, Storage} from "aws-amplify";
import prettySize from "../../Services/prettySize";
import {Book} from "../../../models";
import moment from "moment";

export default function BookBookEpub({bookId}) {
    const {t} = useTranslation();
    
    const [book, setBook] = useState();
    const fetchBook = async () => {
        setBook(await DataStore.query(Book, bookId))
    }
    useEffect(()=>{
        fetchBook()
        const _bookSubscribe = DataStore.observe(Book, bookId).subscribe(msg=> fetchBook())
        return () => _bookSubscribe.unsubscribe();
        
    }, [bookId]);
    
    const [commURL, setCommUrl] = useState("");
    const [freeURL, setFreeUrl] = useState("");
    
    const getFreeUrl = async () => setFreeUrl(await Storage.get(book.freeEpubData.key, {level: "protected", identityId: book.freeEpubData.identity}))
    const getCommUrl = async () => setCommUrl(await Storage.get(book.epubData.key, {level: "private", identityId: book.epubData.identity}))

    useEffect(()=>{
        if (book) {
            console.log("book", bookId)
            if(book && book.freeEpubData)getFreeUrl();
            if(book && book.epubData)getCommUrl();
        }
    }, [book])
    
    return (
        <Card elevation={4}>
            <CardHeader
                title={t('book.content.fields.epub')}
            />
            <CardContent>
                <EpubLine
                    label={t('book.content.fields.myEpub')}
                    epub={book && book.epubData}
                    link={commURL}
                />
                <EpubLine
                    label={t('book.content.fields.myFreeEpub')}
                    epub={book && book.freeEpubData}
                    link={freeURL}
                />
            </CardContent>
        </Card>
    )
}

function EpubLine({label, epub, link}) {
    return <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <Box flex={2}>
            <Typography variant={"body2"}>
                {label}
            </Typography>
        </Box>
        <Box flex={3}>
            {epub && epub.name && <Chip
                color={"secondary"}
                icon={<MenuBook />}
                label={epub.name}
                style={{marginBottom: 0}}
            />}
        </Box>
        <Box width={150}>
            {epub && !!epub.uploaded && <Typography variant={"body2"}>{moment(epub.uploaded).format('DD/MM/YYYY HH:mm')}</Typography>}
        </Box>
        <Box width={100}>
            {epub && !!epub.type && <Typography variant={"body2"}>{epub.type.replace("application/", "")}</Typography>}
        </Box>
        <Box width={60}>
            {epub && !!epub.size && <Typography variant={"body2"}>{prettySize(epub.size)}</Typography>}
        </Box>
        <Box width={100} textAlign={"right"}>
            {epub && <Tooltip title={"generic.openFile"}>
                <IconButton component={Link} href={link} target={"_blank"}>
                    <OpenInNew/>
                </IconButton>
            </Tooltip>}
        </Box>
    </Box>
}