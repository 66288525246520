import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {Auth, DataStore, Storage} from "aws-amplify";
import {Draft, DraftContent} from "../../../models";
import {v4 as uuidv4} from "uuid";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import FieldSet from "../../Misc/FieldSet";
import {Save, Upload} from "@mui/icons-material";
import prettySize from "../../Services/prettySize";
import {makeStyles, styled} from "@mui/styles";
import awsExports from "../../../aws-exports";


export function BookDraftContentsCreate({draftId, onClose, initOrder}) {
    const {t} = useTranslation();
    const [title, setTitle] = useState("");
    const [label, setLabel] = useState("");
    const [order, setOrder] = useState(initOrder);
    const {user} = useAuthenticator();
    const [audioFile, setAudioFile] = useState();
    const [audioFileFree, setAudioFileFree] = useState("");
    
    const handleAudioFile = async (e) => setAudioFile(e.target.files[0])
    const handleAudioFileFree = async (e) => setAudioFileFree(e.target.files[0])
    const [myDraft, setMyDraft] = useState("");
    const fetchDraft = async () => {
        const _draft = await DataStore.query(Draft, draftId)
        setMyDraft(_draft)
    }
    useEffect(()=>{
        if(draftId) {
            fetchDraft()
        }
    }, [draftId])
    
    const handleSave = async () => {
        console.log("draftId", draftId);
        let _key;
        if (audioFile) {
            const uuid = uuidv4();
            const file = await Storage.put(uuid, audioFile, {
                level: "private",
                contentType: audioFile.type
            });
            _key = file.key
        }
        let _keyFree;
        if (audioFileFree) {
            const uuidFree = uuidv4();
            const fileFree = await Storage.put(uuidFree, audioFileFree, {
                level: "public",
                contentType: audioFileFree.type
            });
            _keyFree = fileFree.key

        }

        const _newContent = {
            title: title,
            label: label,
            order: order,
            sub: user.attributes.sub,
            draft: myDraft,
        }
        if(_key)_newContent.audio = _key;
        if(_key)_newContent.audioFile = {
            "region" : awsExports.aws_user_files_s3_bucket_region,
            "bucket" : awsExports.aws_user_files_s3_bucket,
            "key": _key
        }
        if(_keyFree)_newContent.freeAudio = _keyFree

        const newContent = new DraftContent(_newContent)
        console.log("newContent", newContent);
        const _content = DataStore.save(newContent).then(result=>onClose());
    }
    
    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle sx={{backgroundColor: 'primary.main'}} >
            {t('book.content.operations.create-dialog')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={8}>
                    <TextField
                        variant={"outlined"}
                        label={t('book.content.fields.label')}
                        value={label}
                        onChange={event => setLabel(event.target.value)}
                        helperText={t('book.content.fields.label-helper')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        label={t('book.content.fields.order')}
                        type={"number"}
                        value={order}
                        onChange={event => setOrder(event.target.value)}
                        helperText={t('book.content.fields.order-helper')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        label={t('book.content.fields.title')}
                        value={title}
                        onChange={event => setTitle(event.target.value)}
                        helperText={t('book.content.fields.title-helper')}
                    />
                </Grid>
                <Grid item xs={12} hidden={myDraft.bookType === "Audio" ? false : true}>
                    <FieldSet title={t('book.content.fields.audio-file')}>
                        <Typography variant={"body1"}>
                            {t('book.content.fields.audio-file-helper')}
                        </Typography>
                        <Grid container flexDirection={"row"} mt={1}>
                            <Grid>
                                <label htmlFor="contained-button-file">
                                    <Input accept="*" id="contained-button-file" type="file" accept="audio/mpeg, audio/mp3" onChange={handleAudioFile} />
                                    <Button
                                        variant="contained"
                                        color={"secondary"}
                                        component="span"
                                        startIcon={<Upload />}
                                        fullWidth
                                    >
                                        {audioFile ? t('book.content.operations.file-change') : t('book.content.operations.file-choose')}
                                    </Button>
                                </label>
                            </Grid>
                            {audioFile && <Grid item flexGrow={1} p={1}>
                                {audioFile.name}
                            </Grid>}
                            {audioFile && <Grid item p={1}>
                                {prettySize(audioFile.size)}
                            </Grid>}
                        </Grid>
                    </FieldSet>
                </Grid>
                <Grid item xs={12} hidden={myDraft.bookType === "Audio" ? false : true}>
                    <FieldSet title={`${t('book.content.fields.audio-file')} ${t('book.content.fields.freeContent')}`} >
                        <Typography variant={"body1"}>
                            {t('book.content.fields.audio-file-helper')}
                        </Typography>
                        <Grid container flexDirection={"row"} mt={1}>
                            <Grid>
                                <label htmlFor="contained-button-file-free">
                                    <Input accept="*" id="contained-button-file-free" type="file" accept="audio/mpeg, audio/mp3" onChange={handleAudioFileFree} />
                                    <Button
                                        variant="contained"
                                        color={"secondary"}
                                        component="span"
                                        startIcon={<Upload />}
                                        fullWidth
                                    >
                                        {t('book.content.operations.file-free-choose')}
                                    </Button>
                                </label>
                            </Grid>
                            {audioFileFree && <Grid item flexGrow={1} p={1}>
                                {audioFileFree.name}
                            </Grid>}
                            {audioFileFree && <Grid item p={1}>
                                {prettySize(audioFileFree.size)}
                            </Grid>}
                        </Grid>
                    </FieldSet>
                </Grid>
            </Grid>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color={"secondary"} variant={"contained"}>
                {t('generic.cancel')}
            </Button>
            <Button
                startIcon={<Save />}
                variant={"contained"}
                disabled={(!title && !label)}
                onClick={handleSave}
            >
                {t('book.content.operations.create-perform')}
            </Button>
        </DialogActions>
    </Dialog>
}


const Input = styled('input')({
    display: 'none',
});

const useStyles = makeStyles((theme) => ({
    rightAlign: {
        marginLeft: 'auto',
    },
    subCardHeader: {
        height: 66
    },
    subCardContent: {
        height: 120
    }
}))