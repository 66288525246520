import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import {DataStore} from "aws-amplify";
import {BookRating, FollowAuthor, Purchase} from "../../models";
import moment from "moment";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {Check, Delete, Money, Star, Person} from "@mui/icons-material";


export default function FollowList({authorSub, bookId, readerId}) {
    const {t, i18n} = useTranslation();
    
    const [follows, setFollows] = useState([]);
    const fetchFollow = async () => {
        const _follows = await DataStore.query(FollowAuthor)
        if (authorSub)setFollows(_follows.filter(p=>p.authorSub===authorSub));
        else if (bookId)setFollows(_follows.filter(p=>p.book.id===bookId));
        else if (readerId)setFollows(_follows.filter(p=>p.readerUser.id===readerId));
        else setFollows(_follows)
    }
    useEffect(()=>{
        fetchFollow()
        const obsFollow = DataStore.observe(FollowAuthor).subscribe(msg=>{
            console.log("Observe follow", msg)
            if (authorSub && (msg.element.authorSub === authorSub))fetchFollow();
            else if (bookId && (msg.element.bookID === bookId || msg.element.book.id === bookId))fetchFollow();
            else if (readerId && (msg.element.readerUserID === readerId || msg.element.readerUser.id === readerId))fetchFollow();
            else fetchFollow();
        })
        return ()=>{
            obsFollow.unsubscribe();
        }
    }, [])
    
    useEffect(()=>{
        if (follows) {
            console.log("follows", follows);
        }
    }, [follows])
    
    const handleDelete = async (followId) => {
        const follow = follows.find(p => p.id === followId);
        if (follow && ["Failed", "Pending"].includes(follow.status)) {
            const _delete = await DataStore.delete(follow);
            console.log("_delete", _delete)
        }
    }
    
    const _actions = params => {
        if (["Failed", "Pending"].includes(params.row.status)) {
            return <IconButton onClick={()=>handleDelete(params.row.id)}><Delete /></IconButton>
        }
    }
    
    const columns = [
        {field: '_version',     headerName: "v",                  width:40},
        {field: '_deleted',     headerName: "d",                  width:40,  renderCell: params => params.value ? "x" : ""},
        {field: 'createdAt',    headerName: "Date",               width:160, renderCell: params => moment(params.value).format('DD/MM/YYYY HH:mm')},
        {field: 'author',       headerName: t("author.author"),   flex:1,    renderCell: params => `${params.row.author.firstName} ${params.row.author.name}`},
        {field: 'reader',       headerName: t('purchase.reader'), flex:1,    renderCell: params => params.row.readerUser.email},
        {field: 'like',         headerName: t('follow'),          width:80,  renderCell: params => params.value ? <Check /> : ""},
        {field: 'action',       headerName: ' ',                  width:80,  renderCell: _actions},
    ];
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        setRows(follows)
    }, [filter, follows])
    
    return <Card elevation={4}>
        <CardHeader
            avatar={<Avatar><Person /></Avatar>}
            title={<Typography variant={"h6"}>{t('follow.followList')}</Typography>}
        />
        <CardContent>
            <DataGrid
                density={"compact"}
                autoHeight
                rows={rows}
                columns={columns}
                hideFooter={rows.length <= 100}
            />
        </CardContent>
    </Card>
}