import React, {useEffect, useState} from 'react'
import {I18n} from 'aws-amplify';
import {Authenticator, Image, View} from '@aws-amplify/ui-react';
import {translations} from "@aws-amplify/ui-react";
import {ThemeProvider, createTheme} from "@mui/material/styles";
import '@aws-amplify/ui-react/styles.css';
import './aws-theme.css'
import Layout from './components/Layout/Layout';

I18n.putVocabularies(translations);
I18n.setLanguage("fr")

export default function App() {
    const components = {
        Header() {
            //const {tokens} = useTheme();
            
            return (
                <View textAlign="center" >
                    <Image
                        alt="Read-ing"
                        src="/logo-reading.svg"
                        height={200}
                    />
                </View>
            );
        },
    }
    const [user, setUSer]= useState();
    
    return (
        <ThemeProvider theme={theme}>
            <Authenticator
                locale={"fr"}
                components={components}
                hideSignUp={true}
            >
                {({ signOut, user }) => (
                    <UserChecker user={user} signOut={signOut} />
                )}
            </Authenticator>
        </ThemeProvider>
    );
}

function UserChecker({signOut, user}) {
    const [ready, setReady] = useState(false);
    useEffect(()=>{
        if (user) {
            if (!user.signInUserSession?.accessToken?.payload["cognito:groups"] || !user.signInUserSession?.accessToken?.payload["cognito:groups"].includes("Admin")) {
                signOut()
            }
            else {
                setReady(true)
            }
        }
    }, [user])
    
    return ready ? <Layout/> : null
}

//theme
const theme = createTheme({
    
    palette: {
        type: 'dark',
        info: {
            main: '#000000'
        },
        primary: {
            main: '#f39200',
        },
        secondary: {
            main: '#e8c89b'
        },
        background: {
            default: '#f6f6f6',
        },
        grillColor: {
            default: '#f39200'
        }
        
    },
    shape: {
        borderRadius: 12
    },
});