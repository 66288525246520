import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {DataStore, Storage} from "aws-amplify";
import {Author, AuthorUser, Book, Draft, FollowAuthor} from "../../models";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {Check, Close, Delete, Edit, Search, VisibilitySharp, Warning} from "@mui/icons-material";
import {
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    FormControlLabel, Link,
    Switch,
    TextField,
    Typography
} from "@mui/material";
import {NavLink, useNavigate} from "react-router-dom";
import {chooseCorrectKey} from "../Services/correctPath";
import PublicAuthorEdit from "./PublicAuthorEdit";


export default function PublicAuthorList2() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    //AuthorUSer
    
    const [authors, setAuthors] = useState([]);
    const fetchAuthors = async () => setAuthors((await DataStore.query(Author)));
    
    const [followers, setFollowers] = useState([]);
    const fetchFollowers = async () => setFollowers(await DataStore.query(FollowAuthor))
    
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks(await DataStore.query(Book))
    
    const [drafts, setDrafts] = useState([]);
    const fetchDrafts = async () => setDrafts(await DataStore.query(Draft))
    
    useEffect(()=>{
        fetchAuthors();
        fetchBooks();
        fetchDrafts();
        fetchFollowers();
        const obsAuthors = DataStore.observe(Author).subscribe(msg=> {fetchAuthors()});
        const obsFollowers = DataStore.observe(FollowAuthor).subscribe(msg=> {fetchFollowers()});
        return ()=>{
            obsAuthors.unsubscribe();
            obsFollowers.unsubscribe();
        }
    }, [])

    const [edited, setEdited] = useState();
    const [toDelete, setToDelete] = useState();
    const handleDelete = async () => {
        const _author = authors.find(a=>a.id===toDelete);
        if (_author) {
            await DataStore.delete(_author)
        }
        setToDelete(false)
    }
    
    useEffect(()=>console.log('authors', authors), [authors]);
    
    // DATAGrid PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            {!params.row.authorUser && <IconButton size={"small"} onClick={() => setToDelete(params.row.id)}><Delete/></IconButton>}
            {/*<IconButton size={"small"} onClick={()=>navigate(params.row.id)}><VisibilitySharp /></IconButton>*/}
            <IconButton size={"small"} onClick={()=>setEdited(params.row.id)}><Edit /></IconButton>
        </>;
    }
    
    const [editMode, setEditMode] = useState(false);
    
    const _editWeight = async (params, event) => {
        console.log(params, event)
        await DataStore.save(Author.copyOf(params.row, updater => {
            updater[params.field] = parseInt(event.target.value)
        }))
    }

    const _renderBoolean = (params) => {
        return params.value ? <Check /> : ""
    }
    
    const _authorUser = (params) => {
        return params.row.authorUser
            ? <Link underline={"hover"} color="black" component={NavLink} to={`/authors/${params.row.authorUser.id}`}>
                {params.row.authorUser.email}
            </Link>
            : <Warning />
    }
    
    const _followers = (params) => {
        return followers.filter(f => f.author && f.author.id === params.row.id && f.like === true).length || ""
    }
    
    const _avatar = (params) => {
        return <AuthorAvatar avatar={params.row.avatarKeys} />
    }

    const columns = [
        {field: '_version',             headerName: "Version",      width:40 },
        {field: 'avatarKeys',           headerName: "Avatar",       width:60, renderCell: _avatar },
        {field: 'authorUser',           headerName: "Propriétaire", flex:1, renderCell: _authorUser },
        {field: 'sub',                  headerName: "Sub",          flex:1 },
        {field: 'firstName',            headerName: "Prénom",       flex:1 },
        {field: 'name',                 headerName: "Nom",          flex:1 },
        {field: 'email',                headerName: "Email",        flex:1 },
        {field: 'followCount',          headerName: "Followers",    width:60, renderCell: _followers },
        {field:'homeWeight',            headerName: `${t('book.weight.home')}`,     width:60, align: 'right', editable: editMode},
        // {field:'familyWeight',      headerName: `${t('book.weight.family')}`,   width:60, align: 'right', editable: editMode},
        // {field:'categoryWeight',    headerName: `${t('book.weight.category')}`, width:60, align: 'right', editable: editMode},
        {field: 'books',                headerName: "Books",        width:60, renderCell: params => (books.filter(book=>book.author.id === params.row.id).length || "")},
        {field: 'drafts',               headerName: "Drafts",       width:60, renderCell: params => (drafts.filter(draft=>draft.author.id === params.row.id).length || "")},
        {field: 'action',               headerName: ' ',            width:120, renderCell: _renderActions, disableColumnMenu: true, align: 'right' }
    ]
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        if (filter) {
            setRows(authors.filter(author=>author.firstName.toLowerCase().includes(filter.toLowerCase())||author.lastName.toLowerCase().includes(filter.toLowerCase())))
        }
        else {
            setRows(authors)
        }
        
    }, [authors, filter])
    
    
    // const rows = authorUsers;

    return (
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>{t("author.list")}</Typography> }
                action={
                    <FormControlLabel value={editMode} control={<Switch />} label={'Promotion'} onChange={event=>setEditMode(event.target.value)} />
                }
            />
            <CardContent>
                <TextField
                    value={filter}
                    InputProps={{
                        startAdornment: <Search />,
                        endAdornment: filter !== "" && <IconButton onClick={()=>setFilter("")}><Close /></IconButton>
                    }}
                    size={"small"}
                    onChange={event=>setFilter(event.target.value)}
                />
            </CardContent>
            <CardContent>
                <DataGrid
                    density={"compact"}
                    autoHeight
                    rows={rows}
                    columns={columns}
                    onCellEditStop={_editWeight}
                />
                {/*{edited && <AuthorUserEdit authorUserId={edited} onClose={()=>setEdited(null)} />}*/}
            </CardContent>
            {toDelete && <Dialog open={true} onClose={()=>setToDelete(false)} maxWidth={"sm"} fullWidth>
                <DialogTitle>{t('author.operations.deleteDialog')}</DialogTitle>
                <DialogContent>{t('author.operations.deleteHelper')}</DialogContent>
                <DialogActions>
                    <Button
                        onClick={()=>setToDelete(false)}
                        variant={"contained"}
                        color={"info"}
                    >
                        {t('author.operations.deleteCancel')}
                    </Button>
                    <Button
                        onClick={handleDelete}
                        variant={"contained"}
                        color={"error"}
                    >
                        {t('author.operations.deletePerform')}
                    </Button>
                </DialogActions>
            </Dialog>}
            {edited && <PublicAuthorEdit authorId={edited} onClose={()=>setEdited(false)} />}
        </Card>
    )
}

function AuhorEdit({}) {

}

function AuthorAvatar({avatar}) {
    const [avatarURL, setAvatarURL] = useState("");
    const fetchAvatar = async () => {
        if (avatar && avatar.sm) {
            setAvatarURL(await Storage.get(chooseCorrectKey(avatar.sm, {level: 'protected'}), {level: 'protected', identityId: avatar.identity}))
        }
    }
    useEffect(()=>fetchAvatar(),[]);
    return avatarURL ? <Avatar src={avatarURL} /> : <Avatar />
}