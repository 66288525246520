import React from "react";
import {makeStyles} from "@mui/styles";
import {Route, Routes} from "react-router-dom";
import BookDraft from "./Draft/BookDraft";
import BookBook from "./Published/BookBook";
import BookPublicationWantedList from "../Book/BookPublicationWantedList";
import BooksPublished from "./BooksPublished";
import BooksDrafts from "./BooksDrafts";

// import {useNavigate} from "react-router";

export default function Books() {
    return (
        <>
            <Routes>
                <Route path={"drafts"} element={<BooksDrafts />} />
                <Route path={"drafts/:draftId/*"} element={<BookDraft />} />
                <Route path={"published"} element={<BooksPublished />} />
                <Route path={"published/:bookId/*"} element={<BookBook />} />
                <Route path={"publicationWanted"} element={<BookPublicationWantedList />} />
                <Route path={"publicationWanted/:draftId/*"} element={<BookDraft />} />
            </Routes>
        </>
    );
}