import React, {useEffect, useState} from "react";
import {Alert, Avatar, Box, Card, CardContent, CardHeader, CardMedia, Dialog, DialogContent, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack, Typography} from "@mui/material";
import {DataStore, Storage} from "aws-amplify";
import {Author, Draft, Family, Category, SubCategory} from "../../../models";
import {useTranslation} from "react-i18next";
import {Bookmarks, Edit, Visibility, Sell, Category as CategoryIcon, ErrorOutline, LibraryBooks, QuestionMark, Mic, TextFields} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {BookDraftContents} from "./BookDraftContents";
import {Route, Routes, useParams} from "react-router-dom";
import {BookDraftContent} from "./BookDraftContent";
import {BookDraftEditAuthor, BookDraftEditCategory, BookDraftEditInfo, BookDraftEditIsbn, BookDraftEditPrice, BookDraftEditSummary} from "./BookDraftEditDialogs";
import BookDraftStatus from "./BookDraftStatus";
import BookCoverUpload from "./BookCoverUpload";
import BookDraftEpub from "./BookDraftEpub";
import {tdb} from "../../Services/translateJSON";
import {BookImgModal, BookInfo} from "../BookCommon";
import {chooseCorrectKey} from "../../Services/correctPath";

export default function BookDraft() {
    const {draftId} = useParams();
    const {t} = useTranslation();
    const classes = useStyles();
    
    // GET DRAFT
    const [draft, setDraft] = useState("");
    const fetchDraft = async () => setDraft(await DataStore.query(Draft, draftId));
    useEffect(()=>{
        fetchDraft()
        const _draftSubscribe = DataStore.observe(Draft).subscribe((msg)=> {
            if (msg.element.id === draftId)fetchDraft()
        })
        return () => {
            _draftSubscribe.unsubscribe()
        }

    }, [draftId]);
    
    // MODALS
    const [infoEditMode, setInfoEditMode] = useState(false);
    const [authorEditMode, setAuthorEditMode] = useState(false);
    const [categoryEditMode, setCategoryEditMode] = useState(false);
    const [priceEditMode, setPriceEditMode] = useState(false);
    const [isbnEditMode, setIsbnEditMode] = useState(false);
    
    const [coverEditMode, setCoverEditMode] = useState(false);
    const [coverViewMode, setCoverViewMode] = useState(false);
    const [summaryEditMode, setSummaryEditMode] = useState(false);
    
    // TYPE and STATUS DEPENDENCIES
    const [editable, setEditable] = useState(true);
    const [avatarType, setAvatarType] = useState();
    
    // Author
    const [author, setAuthor] = useState("");
    const fetchAuthor = async () => {setAuthor(await DataStore.query(Author, draft.author.id))}
    const [avatarURL, setAvatarURL] = useState();
    const fetchAvatarURL = () => {
        Storage.get(chooseCorrectKey(author.avatarKeys.sm, {level: 'protected'}), {
            level: 'protected',
            identityId: author.avatarKeys.identity
        })
            .then(_image => setAvatarURL(_image))
    }
    useEffect(()=>{if (author && author.avatarKeys && author.avatarKeys.sm)fetchAvatarURL()}, [author])
    
    // Family & Category
    // Family & Category
    const [family, setFamily] = useState();
    const [category, setCategory] = useState();
    const [subcategory, setSubcategory] = useState();
    const fetchFamily = async () => setFamily(await DataStore.query(Family, draft.family.id))
    const fetchCategory = async () => setCategory(await DataStore.query(Category, draft.category.id))
    const fetchSubcategory = async () => setSubcategory(await DataStore.query(SubCategory, draft.subCategory.id))
    
    // cover image
    const [imageURL, setImageURL] = useState("");
    const fetchImageURL = () => {
        Storage
            .get(chooseCorrectKey(draft.coverKeys.md, {level: 'protected'}), {
                level: 'protected',
                identityId: draft.coverKeys.identity
            })
            .then(_image => setImageURL(_image))
    }
    
    useEffect(()=>{
        if (draft) {
            switch (draft.bookType) {
                case "Audio": setAvatarType(<Mic fontSize={"large"} />);break;
                case "Epub": setAvatarType(<LibraryBooks fontSize={"large"} />);break;
                case "Text": setAvatarType(<TextFields fontSize={"large"} />);break;
                default: setAvatarType(<QuestionMark fontSize={"large"} />);break
            }
        }
        if(draft && draft.family)fetchFamily()
        if(draft && draft.category)fetchCategory()
        if(draft && draft.subCategory)fetchSubcategory()
        if(draft && draft.author)fetchAuthor()
        if(draft && draft.coverKeys && draft.coverKeys.md) fetchImageURL();
        setEditable(draft && ["Draft", "Updating"].includes(draft.status))
    }, [draft])
    
    // author avatar image
    
    const cols = {
        lg:3,
        md:6,
        xs:12,
    }

    useEffect(()=>{
        console.log("DRAFT", draft)
    }, [draft])

    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction={"row"} spacing={2} justifyItems={"center"}>
                    <Box pt={1}>
                        <Avatar sx={{height:60, width:60}}>
                            {avatarType}
                        </Avatar>
                    </Box>
                    <Box flexGrow={1}>
                        <Stack direction={"column"}>
                            <Typography variant={"h4"}>
                                {draft && draft.title}
                            </Typography>
                            <Typography variant={"subtitle1"}>
                                {draft && draft.description}
                            </Typography>
                        </Stack>
                    </Box>
                    <Box>
                        {draft && draft.bookType}
                    </Box>
                </Stack>
                
            </Grid>
            {/* INFOS */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.fields.infos")}
                        action={<IconButton disabled={!editable} onClick={()=>{setInfoEditMode(true)}}><Edit /></IconButton>}
                    />
                    <CardMedia className={classes.subCardContent}>
                        <List disablePadding dense>
                            <BookInfo
                                avatar={<Avatar src={avatarURL} />}
                                primary={"Auteur"}
                                secondary={`${author && author.firstName} ${author && author.name}`}
                                danger={!author}
                                edit={()=>setAuthorEditMode(true)}
                                editable={editable}
                            />
                            <BookInfo
                                avatar={<CategoryIcon />}
                                primary={`${family && tdb(family.title)}`}
                                secondary={`${category && tdb(category.title)}${subcategory && ' / '+tdb(subcategory.title)}`}
                                edit={()=>setCategoryEditMode(true)}
                                editable={editable}
                            />
                            <BookInfo
                                avatar={<Sell />}
                                primary={"Prix"}
                                secondary={`${draft && draft.price} KAU`}
                                danger={draft && !draft.price}
                                edit={()=>setPriceEditMode(true)}
                                editable={editable}
                            />
                            <BookInfo
                                avatar={<Bookmarks />}
                                primary={`ISBN`}
                                secondary={`${draft && draft.isbn}`}
                                danger={draft && !draft.isbn}
                                edit={()=>setIsbnEditMode(true)}
                                editable={editable}
                            />
                        </List>
                    </CardMedia>
                </Card>
            </Grid>
            {/* SUMMARY */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.fields.summary")}
                        action={<IconButton disabled={!editable} onClick={()=>{setSummaryEditMode(true)}}><Edit /></IconButton>}
                    />
                    <CardContent className={classes.subCardContent}>
                        {draft && !!draft.summary && <Typography variant={"body2"}>{draft.summary}</Typography>}
                        {draft &&  !draft.summary && <Alert severity={"error"}>Résumé manquant</Alert>}
                    </CardContent>
                </Card>
            </Grid>
            {/* COVER */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.cover.card")}
                        action={
                            <Stack direction="row" spacing={0}>
                                <IconButton disabled={!editable} onClick={()=>setCoverEditMode(true)}><Edit /></IconButton>
                                <IconButton disabled={!imageURL} onClick={()=>setCoverViewMode(true)}><Visibility /></IconButton>
                            </Stack>
                            }
                    />
                    {imageURL
                        ? <CardMedia
                            className={classes.subCardContent}
                            component="img"
                            height="140"
                            image={imageURL}
                            />
                        : <CardContent className={classes.subCardContent}>
                            <Alert severity={"error"} square>{t('Image manquante')}</Alert>
                        </CardContent>
                    }
                    
                </Card>
            </Grid>
            {/* STATUS */}
            <Grid item {...cols}>
                <Card elevation={4}>
                    <CardHeader
                        className={classes.subCardHeader}
                        title={t("book.status.card")}
                    />
                    <BookDraftStatus draft={draft} />
                </Card>
            </Grid>
            {/* EPUB */}
            {draft && draft.bookType === "Epub" && <Grid item xs={12}>
                <BookDraftEpub draftId={draftId} draft={draft} editable={editable} />
            </Grid>}
            {/* CONTENT */}
            {draft && draft.bookType !== "Epub" && <Routes>
                <Route path={""} element={<BookDraftContentsAlone draftId={draftId} />} />
                <Route path={":contentId"} element={<BookDraftContentsAndContent />} />
            </Routes>}
        </Grid>
        {infoEditMode       && <BookDraftEditInfo      onClose={()=>setInfoEditMode(false)} draftId={draftId}  />}
        {authorEditMode     && <BookDraftEditAuthor    onClose={()=>setAuthorEditMode(false)} draftId={draftId}  />}
        {categoryEditMode   && <BookDraftEditCategory  onClose={()=>setCategoryEditMode(false)} draftId={draftId}  />}
        {priceEditMode      && <BookDraftEditPrice     onClose={()=>setPriceEditMode(false)} draftId={draftId}  />}
        {isbnEditMode       && <BookDraftEditIsbn      onClose={()=>setIsbnEditMode(false)} draftId={draftId}  />}
        {summaryEditMode    && <BookDraftEditSummary   onClose={()=>setSummaryEditMode(false)} draftId={draftId}  />}
        {coverEditMode      && <BookCoverUpload        onClose={()=>setCoverEditMode(false)} open={coverEditMode} draftId={draftId} /> }
        {coverViewMode      && <BookImgModal           onClose={()=>setCoverViewMode(false)} open={coverViewMode} urlImg={imageURL} />}
    </Box>
}

function BookDraftContentsAlone({draftId}) {
    return <Grid item xs={12}>
        <BookDraftContents draftId={draftId} full={true} />
    </Grid>
}

function BookDraftContentsAndContent() {
    const {draftId, contentId}    = useParams();
    return <>
        <Grid item xs={12} md={6}>
            <BookDraftContents draftId={draftId} full={false} />
        </Grid>
        <Grid item xs={12} md={6}>
            <BookDraftContent draftId={draftId} contentId={contentId} />
        </Grid>
    </>
}


const useStyles = makeStyles((theme) => ({
    rightAlign: {
        marginLeft: 'auto',
    },
    subCardHeader: {
        height: 66
    },
    subCardContent: {
        height: 250,
        overflow: 'auto'
    },
    chip: {
        marginLeft: theme.spacing(1)
    },
    marginDivider:{
        marginBottom:10,
        marginTop:10
    }
}))