import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {BookContent, Draft, DraftContent} from "../../models";
import IconButton from "@mui/material/IconButton";
import {Add, Error, HomeRepairService, VisibilitySharp} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {NavLink} from "react-router-dom";
import {Button, Card, CardActions, CardContent, CardHeader, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {tdb} from "../Services/translateJSON";
import {BookStatusChip} from "../Misc/Status";
import {aggregateValues} from "../Services/aggregate"
import SearchTextArea from "../Misc/SearchField";
import FieldRange from "../Misc/FieldRange";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import {LocalizationProvider} from "@mui/lab";
import moment from "moment";

export default function BooksDrafts({authorUserId}) {
    const {t, i18n} = useTranslation();

    //DRAFT
    const [drafts, setDrafts] = useState([]);
    const fetchDrafts = async () => {
        setDrafts((await DataStore.query(Draft))
            .filter(d => d.status != "Published")
            .filter(d => !authorUserId || d.authorUser.id === authorUserId)
        )
    }
    useEffect(()=>{
        fetchDrafts();
        const _dratsSubscribe = DataStore.observe(Draft).subscribe(()=>fetchDrafts())
        return () => {
            _dratsSubscribe.unsubscribe()
        }
    }, [])

    //DATAGrid PARAMS

    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} component={NavLink}  to={'/books/drafts/'+params.id}><VisibilitySharp /></IconButton>
        </>;
    }
    
    const _renderStatus = (params) => {
        return <BookStatusChip status={params.value} active={true} />
    }
    
    const _renderAuthorUser = (params) => {
        return `${params.row.authorUser && (params.row.authorUser.firstName || "")} ${params.row.authorUser && (params.row.authorUser.lastName || "")}`
    }
    
    const _renderAuthor = (params) => {
        return `${params.row.author && (params.row.author.firstName || "")} ${params.row.author && (params.row.author.lastName || "")}`
    }

    const columns = [
        {field:'_version',          headerName: "Version", width:70 },
        {field:'createdAt',         headerName: `${t('Date')}`, width:100, renderCell: params => moment(params.value).format("DD/MM/YYYY")},
        {field:'bookType',          headerName: `${t('book.type')}`, width:80 },
        {field:'authorUser',        headerName: `${t('book.author')}`, flex:1, renderCell: _renderAuthorUser, hide: !!authorUserId},
        {field:'author',            headerName: `${t('book.author')}`, flex:1, renderCell: _renderAuthor},
        {field:'title',             headerName: `${t('book.title')}`, flex:1 },
        {field:'family',            headerName: `${t('book.family')}`, flex:2, renderCell: params => (params.row.family ? `${tdb(params.row.family.title)} (${params.row.family.name})` : <Error />)},
        {field:'category',          headerName: `${t('book.category')}`, flex:2, renderCell: params => (params.row.category ? tdb(params.row.category.title) : <Error />) },
        {field:'description',       headerName: `${t('book.description')}`, flex:3, hide: true},
        {field:'price',             headerName: `${t('book.price')}`, width:100, align: 'right'},
        {field:'description',       headerName: `${t('book.description')}`, flex:1 },
        {field:'status',            headerName: `${t('book.status-book')}`, width: 180, renderCell: _renderStatus, status:1},
        {field: 'action',           headerName: ' ', width: 100, renderCell: _renderActions, disableColumnMenu: true, align: 'right'}
    ]
    
    // filter categories
    const [catTree, setCatTree] = useState({});
    const makeCatTree = () => {
        const _tree = {}
        drafts.map(draft=>{
            const _fam = draft.family;
            const _cat = draft.category;
            if (_fam && _cat) {
                if (!(_fam.id in _tree)) {
                    _tree[_fam.id] = {
                        title: _fam.title,
                        element: _fam,
                        cats: {},
                        count: 0
                    }
                }
                if (!(_cat.id in _tree[_fam.id].cats)) {
                    _tree[_fam.id].cats[_cat.id] = {
                        title: _cat.title,
                        element: _cat,
                        items: []
                    }
                }
                _tree[_fam.id].cats[_cat.id].items.push(draft);
                _tree[_fam.id].count++
            }
        })
        setCatTree(_tree)
    }
    const [famCat, setFamCat] = useState("all");
    const [famCatType, setFamCatType] = useState("");
    const [famCatId, setFamCatId] = useState("");
    useEffect(()=>{
        if (famCat === "all") {
            setFamCatType("")
            setFamCatId("")
        }
        else {
            const chunks = famCat.split("_")
            setFamCatType(chunks[0])
            setFamCatId(chunks[1])
        }
    }, [famCat])
    
    // other filters
    const [statuses, setStatuses] = useState({});
    const [bookTypes, setBookTypes] = useState({});
    useEffect(()=>{
        makeCatTree();
        setStatuses(aggregateValues(drafts, row=>row.status));
        setBookTypes(aggregateValues(drafts, row=>row.bookType))
    }, [drafts])
    
    
    const [rangeDates, setRangeDates] = useState([null, null]);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("all");
    const [bookType, setBookType] = useState("all");
    const [filtered, setFiltered] = useState([]);
    useEffect(()=>{
        setFiltered(drafts
            .filter(d=>status !== "all" ? d.status === status : true)
            .filter(d=>bookType !== "all" ? d.bookType === bookType : true)
            .filter(d=>search ? d.title.includes(search) : true)
            .filter(d=>famCatType === "fam" ? d.family && d.family.id === famCatId : true)
            .filter(d=>famCatType === "cat" ? d.category && d.category.id === famCatId : true)
            .filter(d=>(rangeDates[0]) ? (moment(d.createdAt) >= moment(rangeDates[0])) : true)
            .filter(d=>(rangeDates[1]) ? (moment(d.createdAt) <= moment(rangeDates[1])) : true)
        )
    }, [drafts, status, search, bookType, famCatId])
    
    // const [repairDraftMode, setRepairDraftMode] = useState(false);
    // const [repairBookMode, setRepairBookMode] = useState(false);
    
    return <Card>
        <CardHeader
            title={<Typography variant={"h5"}>{t("book.drafts")}</Typography> }
        />
        <CardContent>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <SearchTextArea
                            variant={"outlined"}
                            callBack={value=>setSearch(value)}
                            placeholder={"Recherche"}
                            delay={500}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            select
                            onChange={event=>setFamCat(event.target.value)}
                            value={famCat}
                            fullWidth
                        >
                            <MenuItem value={"all"}>Toutes les categories</MenuItem>
                            {Object.keys(catTree).map(familyId=>[
                                <MenuItem value={`fam_${familyId}`}>
                                    {tdb(catTree[familyId].title)}
                                    {catTree[familyId].count}
                                </MenuItem>,
                                ...Object.keys(catTree[familyId].cats).map(categoryId=>(
                                    <MenuItem value={`cat_${categoryId}`} sx={{paddingLeft:4}}>
                                        {tdb(catTree[familyId].cats[categoryId].title)}
                                        ({catTree[familyId].cats[categoryId].items.length})
                                    </MenuItem>
                                ))
                            ])}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            select
                            onChange={event=>setStatus(event.target.value)}
                            value={status}
                            fullWidth
                        >
                            <MenuItem value={"all"}>Tous les statuts</MenuItem>
                            {Object.keys(statuses).map(k=><MenuItem value={k}>
                                {t(`book.status.all.${k}`)} ({statuses[k].length})
                            </MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            size={"small"}
                            variant={"outlined"}
                            select
                            onChange={event=>setBookType(event.target.value)}
                            value={bookType}
                            fullWidth
                        >
                            <MenuItem value={"all"}>Tous les types</MenuItem>
                            {Object.keys(bookTypes).map(k=><MenuItem value={k}>
                                {t(`${k}`)} ({bookTypes[k].length})
                            </MenuItem>)}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <FieldRange
                            value={rangeDates}
                            setValue={setRangeDates}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {filtered.length > 0 && <DataGrid
                            density={"compact"}
                            autoHeight
                            rows={filtered}
                            columns={columns}
                        />}
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </CardContent>
        {/*<CardActions>
            <Button onClick={()=>setRepairDraftMode(true)}>Repair Draft</Button>
            {repairDraftMode && <RepairDraft onClose={()=>setRepairDraftMode(false)} />}
            <Button onClick={()=>setRepairBookMode(true)}>Repair Book</Button>
            {repairBookMode && <RepairBook onClose={()=>setRepairBookMode(false)} />}
        </CardActions>*/}
    </Card>
}

// used when migrating audio data from string to json
function RepairDraft({onClose}) {
    const [contents, setContents] = useState([]);
    const fetchContent = async () => setContents((await DataStore.query(DraftContent)));
    useEffect(()=>{fetchContent()}, [])
    
    const [audioContents, setAudioContents] = useState([]);
    useEffect(()=>{
        if (contents) {
            setAudioContents(contents.filter(c=>c.draft && c.draft.bookType === "Audio"));
        }
    }, [contents])
    
    const handleRepair = () => {
        audioContents.map(audioContent=>{
            if (typeof audioContent.audio === "string" || typeof audioContent.freeAudio === "string") {
                // perform
                DataStore.save(DraftContent.copyOf(audioContent, updater => {
                    if (typeof audioContent.audio === "string") {
                        updater.audioData = {
                            key: audioContent.audio.replaceAll('"', '')
                        }
                    }
                    if (typeof audioContent.freeAudio === "string") {
                        updater.freeAudioData = {
                            key: audioContent.freeAudio.replaceAll('"', '')
                        }
                    }
                })).then(result=>console.debug(result))
            }
        })
    }
    
    const _renderAudio = (params) => {
        return JSON.stringify(params.value)
    }
    
    const columns = [
        {field: 'id',            flex:1},
        {field: 'audio',         flex:1},
        {field: 'audioData',     flex:2, renderCell: _renderAudio},
        {field: 'freeAudio',     flex:1},
        {field: 'freeAudioData', flex:2, renderCell: _renderAudio},
    ];
    
    return <Dialog open={true} onClose={onClose} maxWidth={"xl"} fullWidth>
        <DialogTitle>Repair</DialogTitle>
        <DialogContent>
            <div>
                {audioContents.length} Contenus Audio / {contents.length} Contenus
            </div>
            <DataGrid
                columns={columns}
                rows={audioContents}
                autoHeight
                density={"compact"}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleRepair}>Repair</Button>
        </DialogActions>
    </Dialog>
}



function RepairBook({onClose}) {
    const [contents, setContents] = useState([]);
    const fetchContent = async () => setContents((await DataStore.query(BookContent)));
    useEffect(()=>{fetchContent()}, [])
    
    const [audioContents, setAudioContents] = useState([]);
    useEffect(()=>{
        if (contents) {
            setAudioContents(contents.filter(c=>c.book && c.book.bookType === "Audio"));
        }
    }, [contents])
    
    const handleRepair = () => {
        audioContents.map(audioContent=>{
            if (typeof audioContent.audio === "string" || typeof audioContent.freeAudio === "string") {
                // perform
                DataStore.save(BookContent.copyOf(audioContent, updater => {
                    if (typeof audioContent.audio === "string") {
                        updater.audioData = {
                            key: audioContent.audio.replace('"', '')
                        }
                    }
                    if (typeof audioContent.freeAudio === "string") {
                        updater.freeAudioData = {
                            key: audioContent.freeAudio.replace('"', '')
                        }
                    }
                })).then(result=>console.debug(result))
            }
        })
    }
    
    const _renderAudio = (params) => {
        return JSON.stringify(params.value)
    }
    
    const columns = [
        {field: 'id',            flex:1},
        {field: 'audio',         flex:1},
        {field: 'audioData',     flex:1, renderCell: _renderAudio},
        {field: 'freeAudio',     flex:1},
        {field: 'freeAudioData', flex:1, renderCell: _renderAudio},
    ];
    
    return <Dialog open={true} onClose={onClose} maxWidth={"xl"} fullWidth>
        <DialogTitle>Repair</DialogTitle>
        <DialogContent>
            <div>
                {audioContents.length} Contenus Audio / {contents.length} Contenus
            </div>
            <DataGrid
                columns={columns}
                rows={audioContents}
                autoHeight
                density={"compact"}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleRepair}>Repair</Button>
        </DialogActions>
    </Dialog>
}