import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Dialog, DialogActions, DialogContent,
    DialogTitle, Grid,
    Link, TextField,
    Typography
} from "@mui/material";
import {DataStore} from "aws-amplify";
import {Payment, Purchase, PurchasedContent, ReaderUser} from "../../models";
import moment from "moment";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {Check, Delete, Error, Money} from "@mui/icons-material";
import {NavLink} from "react-router-dom";


export default function PurchaseList({authorSub, bookId, readerId}) {
    const {t, i18n} = useTranslation();
    
    const [purchases, setPurchases] = useState([]);
    const fetchPurchase = async () => {
        const _purchases = await DataStore.query(Purchase)
        let _filtered = [];
        if (authorSub)      _filtered = _purchases.filter(p=>p.authorSub===authorSub);
        else if (bookId)    _filtered = _purchases.filter(p=>p.book && p.book.id===bookId);
        else if (readerId)  _filtered = _purchases.filter(p=>p.readerUser.id===readerId);
        else _filtered = _purchases
        setPurchases(_filtered.sort((a, b) => a.createdAt === b.createdAt ? 0 : (a.createdAt < b.createdAt ? 1 : -1)))
    }
    
    useEffect(()=>{
        fetchPurchase()
        const obsPurchase = DataStore.observe(Purchase).subscribe(msg=>{
            console.log("Observe purchase", msg)
            if (authorSub && (msg.element.authorSub === authorSub))fetchPurchase();
            else if (bookId && (msg.element.bookID === bookId || (msg.element.book && msg.element.book.id === bookId)))fetchPurchase();
            else if (readerId && (msg.element.readerUserID === readerId || msg.element.readerUser.id === readerId))fetchPurchase();
            else fetchPurchase();
        })
        return ()=>{
            obsPurchase.unsubscribe();
        }
    }, [])
    
    const [reader, setReader] = useState();
    const fetchReader = async () => setReader(await DataStore.query(ReaderUser, readerId));
    useEffect(()=>{
        if (readerId){
            fetchReader();
            const obsReader = DataStore.observe(ReaderUser, readerId).subscribe(msg=>fetchReader);
            return ()=>obsReader.unsubscribe();
        }
    }, [readerId]);
    
    const [purchaseContents, setPurchaseContents] = useState([]);
    const fetchPurchaseContents = async () => {
        const _contents = await DataStore.query(PurchasedContent);
        if (reader)setPurchaseContents(_contents.filter(p=>p.sub===reader.sub));
        else setPurchaseContents(_contents)
    }
    useEffect(()=>{
        if (!readerId || reader) {
            fetchPurchaseContents();
        }
    }, [reader]);
    
    const [toDelete, setToDelete] = useState();
    const [unpaid, setUnpaid] = useState([]);
    useEffect(()=>{if (purchases.length) setUnpaid(purchases.filter(p=>!p.payment))}, [purchases])
    
    const _actions = params => {
        if (["Failed", "Pending"].includes(params.row.status) || !params.row.book) {
            return <IconButton onClick={()=>setToDelete(params.row.id)}><Delete /></IconButton>
        }
    }
    
    const _book = params => {
        if (!params.row.book) {
            return <Error color={"error"} />
        }
        return <Link underline={"hover"} color="black" component={NavLink} sx={{}} to={`/books/published/${params.row.book.id}`}>{params.row.book.title}</Link>
    }
    
    const _reader = params => {
        return <Link underline={"hover"} color="black" component={NavLink} sx={{}} to={`/readers/${params.row.readerUser.id}`}>{params.row.readerUser.email}</Link>
    }
    
    const _payment = params => {
        return params.value ? params.value.transactionId : ""
    }
    
    const _content = (params) => {
        return purchaseContents.filter(c => c.purchase && c.purchase.id === params.row.id).length
    }
    
    const columns = [
        {field: '_version', headerName: "v", width:40},
        {field: '_deleted', headerName: "d", width:40, renderCell: params => params.value ? "x" : ""},
        {field: 'createdAt', headerName: "Date", width:160, renderCell: params => moment(params.value).format('DD/MM/YYYY HH:mm')},
        // {field: 'updatedAt', headerName: "Updated", flex:1, renderCell: params => moment(params.value).format('DD/MM/YYYY HH:mm')},
        {field: 'book', headerName: t("book.book"), flex:1, renderCell: _book},
        {field: 'reader', headerName: t('purchase.reader'), flex:1, renderCell: _reader},
        {field: 'contents', headerName: t('purchase.contents'), width:80, renderCell: _content},
        {field: 'amount', headerName: t('purchase.amount'), width:80},
        {field: 'status', headerName: t('purchase.status'), width:80},
        {field: 'payment', headerName: t('purchase.payback'), width:80, renderCell: _payment},
        {field: 'action', headerName: ' ', width:80, renderCell: _actions},
    ];
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        setRows(purchases)
    }, [filter, purchases])
    
    const [selectionModel, setSelectionModel] = useState([]);
    
    const [payMode, setPayMode] = useState();
    
    const [amount, setAmount] = useState(0);
    useEffect(()=>{
        const _amounts = selectionModel.map(purchaseId => {
            const _purchase = purchases.find(p => p.id === purchaseId)
            return _purchase ? _purchase.amount : 0
        })
        const _amount = _amounts.reduce((p,c)=>p + c, 0)
        setAmount(_amount)
    }, [selectionModel])
    
    const assignPayment = (payment) => {
        const _selection = [...selectionModel];
        _selection.map(purchaseId => {
            const _purchase = purchases.find(p => p.id === purchaseId)
            DataStore.save(Purchase.copyOf(_purchase, updater => {
                updater.payment = payment
            }))
        })
        setSelectionModel([]);
    }
    
    return <Card elevation={4}>
        <CardHeader
            avatar={<Avatar><Money /></Avatar>}
            title={<Typography variant={"h6"}>{t('purchase.purchaseList')}</Typography>}
        />
        <CardContent>
            <DataGrid
                density={"compact"}
                autoHeight
                rows={rows}
                columns={columns}
                // hideFooter={rows.length <= 100}
                checkboxSelection={!!authorSub}
                isRowSelectable={params=>params.row.payment === null}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                components={{ Toolbar: GridToolbar }}
            />
        </CardContent>
        {authorSub && selectionModel.length > 0 && <CardActions>
            <Button variant={"contained"} onClick={()=>setPayMode(true)}>
                Pay {selectionModel.length} Purchases : {amount}
            </Button>
        </CardActions>}
        {payMode && <PaymentDialog selection={selectionModel} onClose={()=>setPayMode(false)} amount={amount} authorSub={authorSub} onSuccess={assignPayment} />}
        {toDelete && <PurchaseDelete purchaseId={toDelete} onClose={()=>setToDelete(false)} />}
    </Card>
}

// Delete purchase
function PurchaseDelete({purchaseId, onClose}) {
    const {t, i18n} = useTranslation();
    
    const [purchase, setPurchase] = useState();
    const fetchPurchase = async () => setPurchase(await DataStore.query(Purchase, purchaseId));
    
    useEffect(()=>console.log('purchase', purchase), [purchase]);
    
    const [purchaseContents, setPurchaseContents] = useState([]);
    const fetchPurchaseContents = async () => {
        const _contents = (await DataStore.query(PurchasedContent)).filter(purchaseContent => (
            purchaseContent._deleted !== true
            && purchaseContent.purchase
            && purchaseContent.purchase.id === purchaseId
        ));
        //const _contents = (await DataStore.query(PurchasedContent, c => c.purchase("eq", purchaseId)));
        
        setPurchaseContents(_contents);
    }
    
    useEffect(()=>{
        if (purchaseId){
            fetchPurchase();
            fetchPurchaseContents();
            
            const obsPurchase = DataStore.observe(Purchase, purchaseId).subscribe(msg=>fetchPurchase);
            // const obsPurchaseContents = DataStore.observe(PurchasedContent).subscribe(msg=> {
            //     console.log(msg)
            //     if(msg.element.purchase && msg.element.purchase.id === purchaseId)fetchPurchaseContents();
            // });
            
            return ()=>{
                obsPurchase.unsubscribe();
                // obsPurchaseContents.unsubscribe();
            }
        }
        
    }, [purchaseId]);
    
    const handleDelete = async () => {
        
        purchaseContents.map(purchaseContent => {
            DataStore.delete(purchaseContent).then(res=>console.log(res))
        })
        
        if (purchaseContents.length === 0) {
            DataStore.delete(purchase);
        }
    }
    
    return <Dialog open={true} onClose={onClose}>
        <DialogTitle>{t('purchase.operations.deleteDialog')}</DialogTitle>
        <DialogContent>
            <Typography>{t('purchase.operations.deleteHelper')}</Typography>
            <Typography>
                {purchase && `Statut : ${purchase.status}`}
            </Typography>
            <Typography>
                {`${purchaseContents.length} ${t("purchase.contents")}`}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button onClick={fetchPurchaseContents}>
                Fetch
            </Button>
            <Button
                variant={"contained"}
                color={"error"}
                onClick={handleDelete}
            >
                {t('purchase.operations.deletePerform')}
            </Button>
        </DialogActions>
    </Dialog>
}

function PaymentDialog({selection, amount, onClose, authorSub, onSuccess}) {
    
    const [formReceipt, setFormReceipt] = useState("");
    const [formType, setFormType] = useState();
    const [formNote, setFormNote] = useState();
    
    const handleCreate = () => {
        const _payment = DataStore.save(new Payment({
            amount: amount,
            sub: authorSub,
            transactionId: formReceipt,
            transactionType: formType,
            note: formNote
        }))
            .then(_payment=>{
                if (_payment.id) {
                    onSuccess(_payment)
                }
            })
            .catch(e => console.log(e))
        
    }
    
    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            Nouveau paiement
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>Commission sur {selection.length} achats</Typography>
                    <Typography>Montant des achats : {amount}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        value={formReceipt}
                        label={"Transaction"}
                        onChange={event=>setFormReceipt(event.target.value)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        value={formType}
                        label={"Type"}
                        onChange={event=>setFormType(event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant={"outlined"}
                        value={formNote}
                        label={"Note"}
                        multiline
                        minRows={2}
                        onChange={event=>setFormNote(event.target.value)}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleCreate}
                disabled={!formReceipt || !formType}
                variant={"contained"}
            >
                Créer
            </Button>
        </DialogActions>
    </Dialog>
}