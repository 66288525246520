import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {DataStore, Storage} from "aws-amplify";
import {Draft, DraftContent} from "../../../models";
import {Alert, Box, Card, CardContent, CardHeader, IconButton, Link, Tab, Typography,} from "@mui/material";
import {Close, DeleteForever, Download, Edit, PlayCircle} from "@mui/icons-material";
import {BookDraftContentsEdit} from "./BookDraftContentEdit";
import parse from 'html-react-parser';
import BookDraftAudioEdit from "./BookDraftAudioEdit";
import BookDraftContentDelete from "./BookDraftContentDelete";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {BookAudioPlayer} from "../BookCommon";
import prettySize from "../../Services/prettySize";

export function BookDraftContent({draftId, contentId}) {
    const {t} = useTranslation();
    
    // load draft
    const [draft,   setDraft]     = useState();
    const fetchDraft =   async () => setDraft((   await DataStore.query(Draft, draftId)))
    useEffect(()=>{
        if (draftId) {
            fetchDraft();
            const observeDraft = DataStore.observe(Draft, draftId).subscribe(()=>fetchDraft())
            return () => {observeDraft.unsubscribe()}
        }
    }, [draftId])
    
    // load content
    const [content, setContent]   = useState();
    const fetchContent = async () => setContent(( await DataStore.query(DraftContent, contentId)))
    useEffect(()=>{
        if (contentId) {
            fetchContent();
            const observeContent = DataStore.observe(DraftContent, contentId).subscribe(msg=>{fetchContent()})
            return ()=>{observeContent.unsubscribe();}
        }
    }, [contentId]);
    
    useEffect(()=>console.log('content', content), [content]);
    
    // audio or text ? take bookType
    const [displayType, setDisplayType] = useState("");
    useEffect(()=>{if (draft)setDisplayType(draft.bookType)}, [draft])
    
    // show emptiness error
    const [error, setError] = useState(false);
    useEffect(()=>{
        let error = false
        if (content) {
            if (displayType === "Audio" && !content.audioData)error = true;
            if (displayType === "Text" && !content.text)error = true;
            if (!content.title && !content.label)error = true;
        }
        setError(error)
    },[displayType, content]);
    
    // modes
    const [editMode, setEditMode] = useState("");
    const [editAudioMode, setEditAudioMode] = useState("");
    const [deleteMode, setDeleteMode] = useState("");
    const [playingData, setPlayingData] = useState(null);
    
    // editable
    const [editable, setEditable] = useState(false);
    useEffect(()=>{
        setEditable(draft && ["Draft", "Updating"].includes(draft.status));
    }, [draft])

    return <Card elevation={4}>
        <CardHeader
            title={content && content.label}
            subheader={content && content.title}
            action={
                <>
                    {displayType === "Text"  && <IconButton disabled={!editable} onClick={() => setEditMode(true)}><Edit/></IconButton>}
                    {displayType === "Audio" && <IconButton disabled={!editable} onClick={() => setEditAudioMode(true)}><Edit/></IconButton>}
                    <IconButton disabled={!editable} onClick={()=>setDeleteMode(true)}><DeleteForever /></IconButton>
                    <IconButton component={NavLink} to={'..'}><Close /></IconButton>
                </>
            }
        />
        {error && <Alert severity={"error"} square>{t('book.content.error-empty')}</Alert>}
        {content && displayType === "Audio" && <CardContent>
            {content.audioData && <AudioData
                label={t('book.content.fields.audio')}
                data={content.audioData}
                action={setPlayingData}
                tempURL={content.audioTempURL}
            />}
            {content.freeAudioData && <AudioData
                label={t('book.content.fields.freeContent')}
                data={content.freeAudioData}
                action={setPlayingData}
                boxProps={{borderTop: 1, borderColor: '#ccc'}}
            />}
        </CardContent>}
    
        
        {content && <TextLayout content={content} />}
        {editMode && <BookDraftContentsEdit draftId={draftId} contentId={contentId} onClose={()=>setEditMode(false)} />}
        {editAudioMode && <BookDraftAudioEdit draftId={draftId} contentId={contentId} onClose={()=>setEditAudioMode(false)} />}
        {playingData && <BookAudioPlayer data={playingData} onClose={()=>setPlayingData(null)} />}
        {deleteMode && <BookDraftContentDelete contentId={contentId} onClose={()=>setDeleteMode(false)} />}

    </Card>
}

export function AudioData({label, data, action, boxProps, tempURL}) {
    const [audioURL, setAudioURL] = useState("");
    const [origin, setOrigin] = useState();
    const fetchAudioURL = async () =>  {
        console.log("data", data)
        console.log("tempURL", tempURL)
        if (data.level && data.level === "private" && tempURL) {
            setOrigin("temp")
            setAudioURL(tempURL)
        }
        else {
            setOrigin("get")
            setAudioURL(await Storage.get(data.key,{level: data.level, ...data.identity ? {identity: data.identity} : null}))
        }
    }
    useEffect(()=>fetchAudioURL(), [data])
    return <Box {...boxProps}>
        
        <Box flexDirection={"row"} display={"flex"} alignItems={"center"}>
            <Box flexGrow={1}>
                {!!label && <Typography><strong>{label}</strong></Typography>}
                <Typography variant={"body2"}>
                    {data.name || data.key }
                    ({data.level} {origin})
                </Typography>
            </Box>
            <Box width={100}>
                <Typography variant={"body2"}>{prettySize(data.size) || '-'}</Typography>
            </Box>
            <Box width={100}>
                <Typography variant={"body2"}>{(data.type && data.type.replace('application/', '')) || '-'}</Typography>
            </Box>
            <IconButton color={"primary"} onClick={()=>action({...data, tempURL: tempURL})}><PlayCircle fontSize={"large"} /></IconButton>
            <IconButton color={"primary"} component={Link} href={audioURL} target={"_blank"} download><Download fontSize={"large"} /></IconButton>
        </Box>
    </Box>
}

export function TextLayout({content}) {
    const {t, i18n} = useTranslation();
    const [value, setValue] = useState("1");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <CardContent sx={{maxHeight: 450, overflowY: "auto"}}>
            {content.freeText && content.freeText.length > 0
                ? <TabContext value={value}>
                    <TabList onChange={handleChange}  aria-label="lab API tabs example">
                        <Tab label={t('book.content.fields.text')} value="1"  />
                        <Tab label={t('book.content.fields.freeContent')} value="2" />
                    </TabList>
                    <TabPanel value="1" >{parse(`${content.text}`)}</TabPanel>
                    <TabPanel value="2">{parse(`${content.freeText}`)}</TabPanel>
                </TabContext>
                : (content.text && content.text.length > 0 ? parse(`${content.text}`) : "")}
        </CardContent>
    )
}



