import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    Avatar,
    Box,
    Button,
    CardActions,
    CardContent,
    Chip,
    Grid, IconButton, List,
    ListItem, ListItemAvatar, ListItemIcon,
    ListItemText,
    Stack,
    TextField
} from "@mui/material";
import {Book, BookContent, Draft, DraftContent} from "../../../models";
import {DataStore} from "aws-amplify";
import {makeStyles} from "@mui/styles";
import {Alert} from "@mui/lab";
import {BookStatusChip} from "../../Misc/Status";
import {useNavigate} from "react-router-dom";
import {MenuBook, Warning} from "@mui/icons-material";

export default function BookDraftStatus({draft}) {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles()
    
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => {
        const _books = await DataStore.query(Book);
        console.debug(_books);
        setBooks(_books.filter(b=>b.draft.id===draft.id))
    }
    useEffect(()=>{if (draft)fetchBooks()}, [draft])
    
    
    const navigateToBook = (book) => {
        navigate(`/books/published/${book.id}`)
    }
    
    return (
        <>
            <CardContent className={classes.subCardContent}>
                <BookStatusChip status={draft.status} active={true} />
                <List dense>
                    <ListItem>
                        <ListItemText>
                            {t('Version publiée :')}
                        </ListItemText>
                    </ListItem>
                    {books.map(book=>(<ListItem button onClick={()=>navigateToBook(book)}>
                        <ListItemIcon sx={{width: 40}}>
                            <MenuBook />
                        </ListItemIcon>
                        <ListItemText>
                            {book.title}
                        </ListItemText>
                    </ListItem>))}
                </List>
            </CardContent>
            <BookStatusButton draft={draft} />
        </>
        
    )
}

function BookStatusButton ({draft}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles();
    const [disabled, setDisabled] = useState(true);
    
    const [contents, setContents] = useState([]);
    const fetchContents = async () => setContents((await DataStore.query(DraftContent)).filter(c=>c.draft && c.draft.id === draft.id));
    
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => {
        const _books = await DataStore.query(Book);
        console.debug(_books);
        setBooks(_books
            .filter(b=>b.draft.id===draft.id)
            .sort((a,b)=>(a._created === b._created ? 0 : (a._created > b._created ? 1 : -1)))
        )
    }
    
    useEffect(()=>{if (draft){
        fetchContents();
        fetchBooks();
    }}, [draft])
    
    const [book, setBook] = useState();
    useEffect(()=>{if (books.length) setBook(books[0])}, [books])
    
    const [bookContents, setBookContents] = useState();
    const fetchBookContents = async () => setBookContents((await DataStore.query(BookContent)).filter(c=>c.book && c.book.id === book.id));
    useEffect(()=>{if (book)fetchBookContents()}, [book])

    useEffect(()=>{
        setDisabled(draft && !["Draft", "PublicationWanted", "UpdateWanted"].includes(draft.status))
    }, [draft])

    //REFUSED
    const handleRefuse = async () => {
        try {
            const _newStatus = Draft.copyOf(draft, updated => {
                updated.status = 'PublicationDeclined'
            })
            await DataStore.save(_newStatus)

        } catch (e) {
            console.log(e)
        }
    }

    //REFUSED
    const handleRefuseUpdate = async () => {
        try {
            const _newStatus = Draft.copyOf(draft, updated => {
                updated.status = 'UpdateDeclined'
            })
            await DataStore.save(_newStatus)

        } catch (e) {
            console.log(e)
        }
    }

    //PUBLISHED

    const handlePublish = async () => {
        console.log("publish draft", draft);
        try {
            const _book= new Book({
                sub: draft.sub,
                bookType: draft.bookType,
                title: draft.title,
                description: draft.description,
                summary:draft.summary,
                cover: draft.cover,
                coverData: draft.coverData,
                coverKeys: draft.coverKeys,
                coverFile: draft.coverFile,
                epub: draft.epub,
                freeEpub: draft.freeEpub,
                epubData: draft.epubData,
                freeEpubData: draft.freeEpubData,
                price: draft.price,
                isbn: draft.isbn,
                author: draft.author,
                family: draft.family,
                category: draft.category,
                subCategory: draft.subCategory,
                draft: draft,
                status: "Published",
                // add field to set as current version
            })
            //Save
            const __book = await DataStore.save(_book)
            console.debug("BOOK", __book);

            //Content
            contents.map((content)=>{
                DataStore.save(new BookContent({
                    sub: content.sub,
                    order: content.order,
                    label: content.label,
                    title: content.title,
                    text: content.text,
                    freeText: content.freeText,
                    audioData: content.audioData,
                    freeAudioData: content.freeAudioData,
                    draftContentID: content.id,
                    book: __book
                }))
                    .then(result => console.log("saved", result))
                    .catch(e => console.log("error", e))
                }
            )

            await DataStore.save(Draft.copyOf(draft, updater => {
                updater.status = "Published"
            }))
        }
        catch (e) {
            console.log( e)
        }
    }
    
    const handleUpdate = async () => {
        const book = books[0];
        const _update = await DataStore.save(Book.copyOf(book, updater => {
            updater.title           = draft.title;
            updater.description     = draft.description;
            updater.summary         = draft.summary;
            updater.cover           = draft.cover;
            updater.coverData       = draft.coverData;
            updater.coverKeys       = draft.coverKeys;
            updater.coverFile       = draft.coverFile;
            updater.epub            = draft.epub;
            updater.freeEpub        = draft.freeEpub;
            updater.epubData        = draft.epubData;
            updater.freeEpubData    = draft.freeEpubData;
            updater.price           = draft.price;
            updater.isbn            = draft.isbn;
            updater.author          = draft.author;
            updater.family          = draft.family;
            updater.category        = draft.category;
            updater.subCategory     = draft.subCategory;
        }))
        
        await Promise.all(await bookContents.map(async bookContent => {
            const content = (bookContent.draftContentID)
                ? contents.find(c=>c.id===bookContent.draftContentID)
                : contents.find(c=>c.order===bookContent.order)
            if (content) {
                return DataStore.save(BookContent.copyOf(bookContent, updater => {
                    // updater.order = content.order;
                    updater.label = content.label;
                    updater.title = content.title;
                    updater.text = content.text;
                    updater.freeText = content.freeText;
                    updater.audioData = content.audioData;
                    updater.freeAudioData = content.freeAudioData;
                }))
            }
        }))
        
        console.log("updated");
        DataStore.save(Draft.copyOf(draft, updater => {
            updater.status = "Published"
        }))
    }

    const [error, setError] = useState(false);
    useEffect(()=>{
        if(!draft.author || !draft.price || !draft.cover) {
            setError(true)
        } else {
            setError(false)
        }
    }, [draft])

    return <CardActions  className={classes.subCardActions} style={{alignItems: "flex-end", justifyContent: "flex-end"}}>
        {error && <Box flexGrow={1}>
            <IconButton color={"error"}><Warning /></IconButton>
        </Box>}
        {draft && draft.status==='PublicationWanted' &&
            <Stack spacing={2} direction="row">
                <Button onClick={handleRefuse} disabled={disabled} variant="outlined">{t('generic.refused')}</Button>
                <Button onClick={handlePublish} disabled={disabled} variant="contained">{t('generic.published')}</Button>
            </Stack>
        }
        {draft && draft.status==='UpdateWanted' &&
            <Stack spacing={2} direction="row">
                <Button onClick={handleRefuseUpdate} disabled={disabled} variant="outlined">{t('generic.refused')}</Button>
                <Button onClick={handleUpdate} disabled={disabled} variant="contained">{t('generic.published')}</Button>
            </Stack>
        }
    </CardActions>

    // return (
    //     `${draft && draft.status}`==='Draft' ? <Button size="small" variant="outlined" onClick={handleFinalized} >{t('book.status.button.finalized')}</Button>
    //         : `${draft && draft.status}`==='Finalized' ? <Stack onClick={handleDraft} spacing={2} direction="row"><Button size="small">{t('book.status.button.draftBack')}</Button><Button size="small" onClick={handlePublicationWanted} variant="outlined" >{t('book.status.button.publicationWanted')}</Button></Stack>
    //             : `${draft && draft.status}`==='PublicationDeclined' ? <Button onClick={handleDraft} variant="outlined" size="small">{t('book.status.button.draftBack')}</Button>
    //                 : `${draft && draft.status}` ==='Published' ? <Stack onClick={handleDraft} spacing={2} direction="row"><Button size="small">{t('book.status.button.draftBack')}</Button></Stack>
    //                     : ""
    // )

}

const useStyles = makeStyles((theme) => ({
    subCardContent: {
        minHeight: 170
    },
    subCardActions: {
        minHeight: 80,
    },
    errorMessage:{
        marginTop:-180
    }

}))




