import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {DataStore} from "aws-amplify";
import {Book, BookContent} from "../../../models";
import {Box, Button, Card, CardActions, CardHeader, CardMedia, IconButton, Typography} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Add, Visibility, VolumeUp} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {useNavigate} from "react-router-dom";
import {BookAudioPlayer} from "../BookCommon";

export function BookBookContents({bookId, full}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles()
    const navigate = useNavigate();
    
    const handleContent = (contentId) => {
        navigate(`/books/published/${bookId}/${contentId}`)
    }
    
    const [book, setBook] = useState();
    const fetchBook = async () => setBook((await DataStore.query(Book, bookId)))
    
    const [contents, setContents] = useState([]);
    const fetchContents = async () => setContents((await DataStore.query(BookContent))
        .filter(c=>c.book&&c.book.id===bookId)
        .sort((a,b)=>a.order===b.order?0:(a.order>b.order?1:-1))
    )
    useEffect(()=>{
        if (bookId){
            fetchContents();
            fetchBook();
            const observeContent = DataStore.observe(BookContent).subscribe(msg=>{fetchContents()})
            const observeBook = DataStore.observe(Book).subscribe(msg => fetchBook())
            return ()=>{
                observeContent.unsubscribe();
                observeBook.unsubscribe()
            }
        }
    }, [bookId]);
    
    useEffect(()=>console.log('contents', contents), [contents]);

    const [disabled, setDisabled] = useState(false);
    useEffect(()=>{
        if(book && book.status != "Book") {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [book])


    const _renderActions = (params) => {
        return <>
            <IconButton onClick={()=>handleContent(params.row.id)}><Visibility /></IconButton>
        </>
    }
    
    const _renderTitle = (params) => {
        return <div>
            <Typography variant={""}>{params.row.label}</Typography>
            <Typography>{params.row.title}</Typography>
        </div>
    }
    const [listenAudioMode, setListenAudioMode] = useState();
    const [audio, setAudio] = useState("");
    const [freeAudio, setFreeAudio] = useState("");
    const [content, setContent] = useState("");
    const [freeMode, setFreeMode] = useState("");
    const _renderAudio = (params) => {
        return params.row.audio
            ?   <IconButton onClick={()=>{setListenAudioMode(true); setContent(params.row); setFreeMode(false)}}><VolumeUp /></IconButton>
            :   "-"
    }
    const _renderFreeAudio = (params) => {
        return params.row.freeAudio
            ?   <IconButton onClick={()=>{setListenAudioMode(true); setContent(params.row); setFreeMode(true)}}><VolumeUp /></IconButton>
            :   "-"
    }
    const _renderChars = (params) => {
        return params.row.text ? params.row.text.length : '-'
    }
    const _renderFreeChars = (params) => {
        return params.row.freeText ? params.row.freeText.length : '-'
    }

    const [textHidden, setTextHidden] = useState("");
    const [audioHidden, setAudioHidden] = useState("");

    useEffect(()=>{
        if(book && book.bookType != "Text") {
            setTextHidden(true)
            setAudioHidden(false)
        }
        else {
            setTextHidden(false)
            setAudioHidden(true)
        }
    }, [book])



    const columns = [
        {field: 'id',           headerName: t('generic.id'),                        hide:true},
        {field: 'order',        headerName: t('book.content.fields.order'),         width: 50, align: 'right'},
        {field: '_version',     headerName: 'V',                                    width: 50, hide: false },
        {field: 'label',        headerName: t('book.content.fields.label'),         hide: false },
        {field: 'title',        headerName: t('book.content.fields.title'),         hide: false , flex: 1},
        {field: 'audio',        headerName: t('book.content.fields.audio'),         hide: audioHidden, width: 150, renderCell: _renderAudio},
        {field: 'freeAudio',    headerName: t('book.content.fields.freeContent'),   hide: audioHidden, width: 150, renderCell: _renderFreeAudio},
        {field: 'chars',        headerName: t('book.content.fields.chars'),         hide: textHidden,  width: full ? 150: 60, renderCell: _renderChars, align: 'right'},
        {field: 'freeChars',    headerName: t('book.content.fields.freeContent'),   hide: textHidden,  width: full ? 150: 60, renderCell: _renderFreeChars, align: 'right'},
        //{field: 'full',       headerName: t('book.content.fields.title'), renderCell: _renderTitle, flex: 1},
        {field: 'actions',      headerName: ' ', renderCell: _renderActions}
    ]

    const [createMode, setCreateMode] = useState();

    return (
        <Card elevation={4}>
            <CardHeader
                title={t('book.content.sections')}
            />

            <CardMedia>
                <div style={{height: 300}}>
                    <DataGrid
                        columns={columns}
                        rows={contents}
                        hideFooter
                        density={"compact"}
                        //onRowDoubleClick={(params)=>handleContent(params.row.id)}
                        onRowClick={(params)=>handleContent(params.row.id)}
                        square
                    />
                </div>
            </CardMedia>
            <CardActions>
                <Box justifyContent={"flex-end"}>
                    <Button disabled={disabled} startIcon={<Add />} variant={"contained"} onClick={()=>setCreateMode(true)}>
                        {t('book.content.operations.create-button')}
                    </Button>
                    {listenAudioMode && <BookAudioPlayer onClose={()=>setListenAudioMode(false)} content={content} freeMode={freeMode}/>}
                </Box>
            </CardActions>
        </Card>
    )
}

const useStyles = makeStyles((theme) => ({
    rightAlign: {
        marginLeft: 'auto',
    },
    subCardHeader: {
        height: 66
    },
    subCardContent: {
        height: 120
    }
}))