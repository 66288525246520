import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {DataStore} from "aws-amplify";
import {Draft, DraftContent} from "../../../models";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {Add, AudioFile, Audiotrack, DeleteForever, Edit, MenuBook, Visibility, VolumeUp} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import {BookDraftContentsCreate} from "./BookDraftContentsCreate";
import {NavLink, useNavigate} from "react-router-dom";
import BookDraftAudioPlayer from "./BookDraftAudioPlayer";
import BookDraftContentDelete from "./BookDraftContentDelete";
import {BookAudioPlayer} from "../BookCommon";

export function BookDraftContents({draftId, full}) {
    const {t, i18n} = useTranslation();
    const classes = useStyles()
    const navigate = useNavigate();
    
    const handleContent = (contentId) => {
        navigate(`/books/drafts/${draftId}/${contentId}`)
    }
    
    const [draft, setDraft] = useState();
    const fetchDraft = async () => setDraft((await DataStore.query(Draft, draftId)))
    
    const [contents, setContents] = useState([]);
    const fetchContents = async () => setContents((await DataStore.query(DraftContent))
        .filter(c=>c.draft&&c.draft.id===draftId)
        .sort((a,b)=>a.order===b.order?0:(a.order>b.order?1:-1))
    )
    useEffect(()=>{
        if (draftId){
            fetchContents();
            fetchDraft();
            const observeContent = DataStore.observe(DraftContent).subscribe(msg=>{fetchContents()})
            const observeDraft = DataStore.observe(Draft).subscribe(msg => fetchDraft())
            return ()=>{
                observeContent.unsubscribe();
                observeDraft.unsubscribe()
            }
        }
    }, [draftId]);

    const [disabled, setDisabled] = useState(false);
    useEffect(()=>{
        if(draft && draft.status != "Draft") {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [draft])


    const _renderActions = (params) => {
        return <>
            <IconButton onClick={()=>handleContent(params.row.id)}><Visibility /></IconButton>
        </>
    }
    
    const _renderTitle = (params) => {
        return <div>
            <Typography variant={""}>{params.row.label}</Typography>
            <Typography>{params.row.title}</Typography>
        </div>
    }
    const [listenAudioMode, setListenAudioMode] = useState();
    
    const [audio, setAudio] = useState("");
    const [freeAudio, setFreeAudio] = useState("");
    
    const [content, setContent] = useState("");
    const [freeMode, setFreeMode] = useState("");
    
    const [playingData, setPlayingData] = useState(null);
    
    const _renderAudio = (params) => {
        return params.value && params.value.key ? <IconButton onClick={()=>{setPlayingData({...params.value, tempURL: params.row.audioTempURL})}}><VolumeUp /></IconButton> : "-"
    }
    const _renderFreeAudio = (params) => {
        return params.value && params.value.key ? <IconButton onClick={()=>{setPlayingData({...params.value, })}}><VolumeUp /></IconButton> : "-"
    }
    
    const _renderChars = (params) => {
        return params.row.text ? params.row.text.length : '-'
    }
    const _renderFreeChars = (params) => {
        return params.row.freeText ? params.row.freeText.length : '-'
    }

    const [textHidden, setTextHidden] = useState("");
    const [audioHidden, setAudioHidden] = useState("");

    useEffect(()=>{
        if(draft && draft.bookType != "Text") {
            setTextHidden(true)
            setAudioHidden(false)
        }
        else {
            setTextHidden(false)
            setAudioHidden(true)
        }
    }, [draft])



    const columns = [
        {field: 'id',            headerName: t('generic.id'),                        hide:true},
        {field: 'order',         headerName: t('book.content.fields.order'),         width: 50, align: 'right'},
        {field: 'label',         headerName: t('book.content.fields.label'),         hide: false },
        {field: 'title',         headerName: t('book.content.fields.title'),         hide: false , flex: 1},
        {field: 'audioData',     headerName: t('book.content.fields.audio'),         hide: audioHidden, width: full ? 150: 60, renderCell: _renderAudio},
        {field: 'freeAudioData', headerName: t('book.content.fields.freeContent'),   hide: audioHidden, width: full ? 150: 60, renderCell: _renderFreeAudio},
        {field: 'chars',         headerName: t('book.content.fields.chars'),         hide: textHidden,  width: full ? 150: 60, renderCell: _renderChars, align: 'right'},
        {field: 'freeChars',     headerName: t('book.content.fields.freeContent'),   hide: textHidden,  width: full ? 150: 60, renderCell: _renderFreeChars, align: 'right'},
        //{field: 'full',        headerName: t('book.content.fields.title'), renderCell: _renderTitle, flex: 1},
        {field: 'actions',       headerName: ' ', renderCell: _renderActions}
    ]

    const [createMode, setCreateMode] = useState();

    return (
        <Card elevation={4}>
            <CardHeader
                title={t('book.content.sections')}
            />

            <CardMedia>
                <div style={{height: 300}}>
                    <DataGrid
                        columns={columns}
                        rows={contents}
                        hideFooter
                        density={"compact"}
                        //onRowDoubleClick={(params)=>handleContent(params.row.id)}
                        onRowClick={(params)=>handleContent(params.row.id)}
                        square
                    />
                </div>
            </CardMedia>
            <CardActions>
                <Box justifyContent={"flex-end"}>
                    <Button disabled={disabled} startIcon={<Add />} variant={"contained"} onClick={()=>setCreateMode(true)}>
                        {t('book.content.operations.create-button')}
                    </Button>
                    {createMode && <BookDraftContentsCreate initOrder={contents.length} draftId={draftId} onClose={() => setCreateMode(false)} />}
                    {playingData && <BookAudioPlayer onClose={()=>setPlayingData(null)} data={playingData} />}
                </Box>
            </CardActions>
        </Card>
    )
}

const useStyles = makeStyles((theme) => ({
    rightAlign: {
        marginLeft: 'auto',
    },
    subCardHeader: {
        height: 66
    },
    subCardContent: {
        height: 120
    }
}))