import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Cancel, Save } from "@mui/icons-material";
import { DataStore } from "aws-amplify";
import { School } from "../../models";
import { tdb } from "../Services/translateJSON";
import { t } from "i18next";

function SchoolCreate({ open, onClose }) {
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState({
    title: "",
    phoneNumber: "",
    fixeNumber: "",
    description: "",
    address: "",
  });
  const handleChange = (field, value) => {
    setForm((state) => ({ ...state, [field]: value }));
  };

  //SAVE
  const handleSave = async () => {
    const _form = {
      title: form.title,
      phoneNumber: form.phoneNumber,
      fixeNumber: form.fixeNumber,
      description: form.description,
      address: form.address,
    };
    try {
      const __form = new School(_form);
      await DataStore.save(__form);
      console.log("school saved successfully!");
      if (onClose)onClose();
    } catch (e) {
      console.log(e);
      console.log("school Not  Saved!");
    }
  };
  
  return (
    <Fragment>
      <Dialog maxWidth={"lg"} open={true} onClose={onClose}>
        <DialogTitle>{t("school.Add")}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Nom")}
                variant="outlined"
                value={form.title}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) => handleChange("title", event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("generic.TeleponeMobile")}
                variant="outlined"
                value={form.phoneNumber}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("phoneNumber", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label={t("generic.TelephoneFixe")}
                variant="outlined"
                value={form.fixeNumber}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("fixeNumber", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("generic.Adresse")}
                variant="outlined"
                value={form.address}
                //   error={!!formErrors.title}
                //   helperText={formErrors.title}
                onChange={(event) =>
                  handleChange("address", event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                value={form.description}
                label={t("generic.Desciption")}
                variant="outlined"
                id="standard-multiline-static"
                multiline
                rows={4}
                onChange={(event) =>
                  handleChange("description", event.target.value)
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Cancel />}
            variant="contained"
             onClick={onClose}
           //onClick={()=>{window.location.reload(false)}}
            color="secondary"
          >
            {t("generic.Cancel")}
          </Button>
          <Button
            startIcon={<Save />}
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            {t("generic.Save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default SchoolCreate;
