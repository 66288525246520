import React, {useEffect, useState} from "react";
import {Link, NavLink, Route, Routes} from "react-router-dom";
import CategoryLayout from "../Category/CategoryLayout";
import Home from "../Home"
import AuthorUserList from "../AuthorUser/AuthorUserList";
import {Badge, ListItemText, MenuItem, MenuList} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Book, Draft} from "../../models"
import Books from "../Book/Books"
import AuthorUsers from "../AuthorUser/AuthorUsers";
import ReaderUsers from "../ReaderUser/ReaderUsers";
import School from "../School/School";
import PublicAuthors from "../PublicAuthor/PublicAuthors";
import PurchaseList from "../Purchase/PurchaseList";
import Products from "../Products/Products";
import Settings from "../Settings/Settings";
import SettingsIcon from '@mui/icons-material/Settings';


export default function Navigation() {
    const {t, i18n} = useTranslation();

    //PublicationWanted Counter
    const [draftsCounter, setDraftsCounter] = useState([]);
    const fetchDraftsCounter = async () => {
        const _draftsCounter = await DataStore.query(Draft)
        setDraftsCounter(_draftsCounter.filter(d=>d.status === "PublicationWanted"))
    }
    useEffect(()=>{
        fetchDraftsCounter();
        const _draftsSubscribe = DataStore.observe(Draft).subscribe(()=>fetchDraftsCounter())
        return () => {
            _draftsSubscribe.unsubscribe()
        }
    }, [])

    //Book.old counter
    const [booksCounter, setBooksCounter] = useState([]);
    const fetchBooksCounter = async () => {
        setBooksCounter(await DataStore.query(Book))
    }

    useEffect(() =>{
        fetchBooksCounter();
        const _bookSubscribe = DataStore.observe(Book).subscribe(()=>fetchBooksCounter())
        return () => {
            _bookSubscribe.unsubscribe()
        }
    }, [])

    return (
      <MenuList dense>
          <MenuItem component={Link} to="/">
              <ListItemText>{t('menu.home')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/readers">
              <ListItemText>{t('menu.readers')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/authors">
              <ListItemText>{t('menu.authors')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/pubauthors">
              <ListItemText>{t('menu.publicAuthors')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/categories">
              <ListItemText>{t('menu.categories')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/books/drafts">
              <ListItemText>{t('menu.draftBook')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/books/publicationWanted">
              <ListItemText><Badge badgeContent={draftsCounter.length} color="primary">{t('menu.publicationWanted')}</Badge></ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/books/published">
              <ListItemText><Badge badgeContent={booksCounter.length} color={"success"}>{t('menu.publishedBook')}</Badge></ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/purchases">
              <ListItemText>{t('menu.purchases')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/schools">
              <ListItemText>{t('menu.schools')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/services">
              <ListItemText>{t('menu.services')}</ListItemText>
          </MenuItem>
          <MenuItem component={Link} to="/settings">
              <ListItemText>{t('menu.settings')}</ListItemText>
          </MenuItem>
      </MenuList>
    )
}



export function LayoutMain({}) {
    return <Routes>
        <Route index element={<Home/>}/>
        <Route path="/books/*" element={<Books />}/>
        <Route path="/categories" element={<CategoryLayout/>}/>
        <Route path="/authors/*" element={<AuthorUsers />} />
        <Route path="/pubauthors/*"  element={<PublicAuthors />} />
        <Route path="/readers/*" element={<ReaderUsers />} />
        <Route path="/purchases/*" element={<PurchaseList />} />
        <Route path="/schools/*" element={<School />} />
        <Route path="/services/*" element={<Products />} />
        <Route path="/settings/*" element={<Settings />} />
    </Routes>
}
