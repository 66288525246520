import {useEffect, useState} from "react";
import {DateRangePicker} from "@mui/lab";
import {Box, Grid, IconButton, TextField} from "@mui/material";
import {Close} from "@mui/icons-material";
import moment from "moment";

export default function FieldRange({value, setValue}) {
    return <DateRangePicker
            calendars={1}
            value={value}
            onChange={(newValue) => {
                setValue(newValue);
            }}
            maxDate={new Date()}
            renderInput={(startProps, endProps) => (
                <Box display={"flex"} flexDirection={"row"}>
                    <Box flexGrow={1} mr={1}>
                        <TextField {...startProps} size={"small"} autoComplete={"off"} />
                    </Box>
                    <Box flexGrow={1} ml={1}>
                        <TextField {...endProps} size={"small"} autoComplete={"off"} />
                    </Box>
                    {/*<IconButton onClick={()=>onChange([null, null])}><Close /></IconButton>*/}
                </Box>
            )}
        />
}