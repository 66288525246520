import {DataStore} from "aws-amplify";
import React, {useEffect, useState} from "react";
import {Purchase, ReaderUser, Refill} from "../../models";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Box, Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Radio, RadioGroup,
    TextField
} from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import moment from "moment";

export default function ReaderUserEdit({readerId, onClose, onSuccess}) {
    const {t, i18n} = useTranslation();
    
    // reader
    const [reader, setReader] = useState();
    const fetchReader = async () => setReader(await DataStore.query(ReaderUser, readerId))
    
    // launch
    useEffect(()=>{if (readerId) {
        // reader
        fetchReader()
        const obsRU = DataStore.observe(ReaderUser, readerId).subscribe(msg=>fetchReader())
    }}, [readerId])
    
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        gender: "",
        phone: "",
        birthday: null,
        address: "",
        zip: "",
        city: "",
        country: "",
        credits: 0,
        unsubscribe: ""
    });
    
    useEffect(()=>{
        if (reader) {
            setForm({
                firstName:      reader.firstName || "",
                lastName:       reader.lastName || "",
                gender:         reader.gender || "",
                phone:          reader.phone || "",
                address:        reader.address || "",
                birthday:       reader.birthday ? moment(reader.birthday) : null,
                zip:            reader.zip || "",
                city:           reader.city || "",
                country:        reader.country || "",
                credits:        reader.credits ? parseInt(reader.credits) : 0,
                unsubscribe:    reader.unsubscribe || false,
            })
        }
    }, [reader])
    
    const handleSave = async () => {
        console.log("form", form)
        const _reader = await DataStore.save(ReaderUser.copyOf(reader, updater => {
            updater.firstName   = form.firstName;
            updater.lastName    = form.lastName;
            updater.gender      = form.gender;
            updater.phone       = form.phone;
            updater.address     = form.address;
            updater.birthday    = moment(form.birthday).toISOString();
            updater.zip         = form.zip;
            updater.city        = form.city;
            updater.country     = form.country;
            updater.credits     = parseInt(form.credits);
            updater.unsubscribe = form.unsubscribe;
        }));
        console.log('_reader from edit', _reader)
        onSuccess();
        onClose();
    }
    
    return <Dialog open={true} onClose={onClose} maxWidth={"md"} fullWidth>
        <DialogTitle>
            {t('reader.operations.updateDialog')}
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2} mt={2}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={form && form.firstName}
                        label={t('reader.form.firstName')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, firstName: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={form && form.lastName}
                        label={t('reader.form.lastName')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, lastName: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <RadioGroup sx={{padding: 2}} row value={form.gender} defaultValue={form && form.gender} onChange={event => setForm( state => ({...state, gender: event.target.value}))}>
                        <FormControlLabel control={<Radio sx={{paddingY: 0}} />} value={t('male')} label={t('authorUser.form.male')} />
                        <FormControlLabel control={<Radio sx={{paddingY: 0}} />} value={t('female')} label={t('authorUser.form.female')} />
                    </RadioGroup>
                </Grid>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                        <DatePicker
                            label={t("reader.form.birthday")}
                            views={["day", "month", "year"]}
                            value={form && form.birthday}
                            onChange={value => setForm( state => ({...state, birthday: value}))}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={form && form.phone}
                        label={t('reader.form.phone')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, phone: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={reader && reader.email || ""}
                        label={t('reader.form.email')}
                        disabled
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        value={form && form.address}
                        label={t('reader.form.address')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, address: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth
                        value={form && form.zip}
                        label={t('reader.form.zip')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, zip: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={form && form.city}
                        label={t('reader.form.city')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, city: event.target.value}))}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={form && form.credits}
                        type={"number"}
                        label={t('reader.form.credits')}
                        variant="outlined"
                        onChange={event => setForm( state => ({...state, credits: parseInt(event.target.value)}))}
                    />
                </Grid>
                <Grid item xs={4}>
                
                </Grid>
                <Grid item xs={4}>
                    <Box p={1}>
                        <FormControlLabel
                            onChange={event => setForm(state => ({...state, unsubscribe: event.target.checked}))}
                            control={<Checkbox checked={form && form.unsubscribe ? !!form.unsubscribe : false} />}
                            label={"Désinscrit"}
                        />
                    </Box>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleSave}
                variant={"contained"}
            >
                {t('reader.operations.updateButton')}
            </Button>
        </DialogActions>
    </Dialog>
}