import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {Avatar, Button, Card, CardActions, CardContent, CardHeader, Link, Typography} from "@mui/material";
import {DataStore} from "aws-amplify";
import {BookLike} from "../../models";
import moment from "moment";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {Check, Delete, Favorite, Money} from "@mui/icons-material";
import {NavLink} from "react-router-dom";


export default function LikeList({authorSub, bookId, readerId}) {
    const {t, i18n} = useTranslation();
    
    const [bookLikes, setBookLikes] = useState([]);
    const fetchBookLike = async () => {
        const _bookLikes = await DataStore.query(BookLike)
        if (authorSub)setBookLikes(_bookLikes.filter(p=>p.authorSub===authorSub));
        else if (bookId)setBookLikes(_bookLikes.filter(p=>p.book.id===bookId));
        else if (readerId)setBookLikes(_bookLikes.filter(p=>p.readerUser.id===readerId));
        else setBookLikes(_bookLikes)
    }
    useEffect(()=>{
        fetchBookLike()
        const obsBookLike = DataStore.observe(BookLike).subscribe(msg=>{
            console.log("Observe bookLike", msg)
            if (authorSub && (msg.element.authorSub === authorSub))fetchBookLike();
            else if (bookId && (msg.element.bookID === bookId || msg.element.book.id === bookId))fetchBookLike();
            else if (readerId && (msg.element.readerUserID === readerId || msg.element.readerUser.id === readerId))fetchBookLike();
            else fetchBookLike();
        })
        return ()=>{
            obsBookLike.unsubscribe();
        }
    }, [])
    
    useEffect(()=>{
        if (bookLikes) {
            console.log("bookLikes", bookLikes);
        }
    }, [bookLikes])
    
    const handleDelete = async (bookLikeId) => {
        const bookLike = bookLikes.find(p => p.id === bookLikeId);
        if (bookLike && ["Failed", "Pending"].includes(bookLike.status)) {
            const _delete = await DataStore.delete(bookLike);
            console.log("_delete", _delete)
        }
    }
    
    const _actions = params => {
        if (["Failed", "Pending"].includes(params.row.status)) {
            return <IconButton onClick={()=>handleDelete(params.row.id)}><Delete /></IconButton>
        }
    }
    
    const _book = params => {
        return <Link underline={"hover"} color="black" component={NavLink} sx={{}} to={`/books/published/${params.row.book.id}`}>{params.row.book.title}</Link>
    }
    
    const _reader = params => {
        return <Link underline={"hover"} color="black" component={NavLink} sx={{}} to={`/readers/${params.row.readerUser.id}`}>{params.row.readerUser.email}</Link>
    }
    
    const columns = [
        {field: '_version', headerName: "v", width:40},
        {field: '_deleted', headerName: "d", width:40, renderCell: params => params.value ? "x" : ""},
        {field: 'createdAt', headerName: "Date", width:160, renderCell: params => moment(params.value).format('DD/MM/YYYY HH:mm')},
        {field: 'book', headerName: t("book.book"), flex:1, renderCell: _book},
        {field: 'reader', headerName: t('purchase.reader'), flex:1, renderCell: _reader},
        {field: 'like', headerName: t("bookLike.like"), width:80, renderCell: params => params.value ? <Check /> : ""},
        {field: 'action', headerName: ' ', width:80, renderCell: _actions},
    ];
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        setRows(bookLikes)
    }, [filter, bookLikes])
    
    return <Card elevation={4}>
        <CardHeader
            avatar={<Avatar><Favorite /></Avatar>}
            title={<Typography variant={"h6"}>{t('bookLike.bookLikeList')}</Typography>}
            action={<Avatar><Typography variant={"h6"}>{bookLikes.length}</Typography></Avatar>}
        />
        <CardContent>
            <DataGrid
                density={"compact"}
                autoHeight
                rows={rows}
                columns={columns}
                hideFooter={rows.length <= 100}
            />
        </CardContent>
    </Card>
}