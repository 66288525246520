import React, {useEffect, useState} from "react";
import {DataStore, Hub} from 'aws-amplify';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@mui/material";
import {Check, Done, Sync, SyncAlt, Warning} from "@mui/icons-material";

export default function HubMessages() {

    const [outBoxStatusIsEmpty, setOutBoxStatusIsEmpty] = useState();

    const [allEvents, setAllEvents] = useState([]);
    const [countEvents, setCountEvents] = useState(0);
    const onEvent = (event) => {
        allEvents.push(event)
        setAllEvents(allEvents);
        setCountEvents(allEvents.length)

        if (event.channel === "datastore" && event.payload.event === "outboxStatus") {
            setOutBoxStatusIsEmpty(event.payload.data.isEmpty)
        }
    }

    const [dialog, setDialog] = useState(false);
    const handleOpenDialog = () => setDialog(true);
    const handleCloseDialog = () => setDialog(false);

    useEffect(()=>{
        console.log("initialize HUB")
        //Hub.listen('core', (data) => {onEvent(data)});
        //Hub.listen('auth', (data) => {onEvent(data)});
        Hub.listen('datastore', (data) => {onEvent(data)});
        //Hub.listen('api', (data) => {onEvent(data)});
    }, [])

    const handleClear = () => {
        if (window.confirm("Sure ?")) {
            DataStore.clear();
        }
    };
    const handleStart = () => {
        if (window.confirm("Sure ?")) {
            DataStore.start();
        }
    };
    const handleEmpty = () => setAllEvents([]);

    const outputData = (channel, payload) => {
        return payload.data ? Object.entries(payload.data).map(([key, value]) => {
            if (typeof(value) === "string")
                return <div key={key}>{key} = {value}</div>
            else
                return <div key={key}>{key} = {JSON.stringify(value, null, ' ')}</div>
        }) : 'no data'
    }

    return <div>
        <IconButton onClick={handleOpenDialog}>
            {<Sync />}
            {outBoxStatusIsEmpty
                ? <Done />
                : <Warning /> }
        </IconButton>
        <Dialog open={dialog} fullWidth maxWidth={"md"} onClose={handleCloseDialog}>
            <DialogTitle>Hub messages</DialogTitle>
            <DialogContent>
                {allEvents.map((event, i) => <Grid container key={i}>
                    <Grid item xs={2}>{event.channel}</Grid>
                    <Grid item xs={2}>{event.payload.event}</Grid>
                    <Grid item xs={8}>{outputData(event.channel, event.payload)}</Grid>
                </Grid>)}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleClear}>Clear DataStore</Button>
                <Button variant="contained" color="primary" onClick={handleStart}>Start DataStore</Button>
                <Button variant="contained" color="primary" onClick={handleEmpty}>Empty LIST</Button>
            </DialogActions>
        </Dialog>
    </div>;
}
