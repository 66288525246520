import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DataStore, Storage} from "aws-amplify";
import {DraftContent} from "../../../models";
import {Delete, Save} from "@mui/icons-material";

export default function BookDraftContentDelete({onClose, contentId}) {

    const {t, i18n} = useTranslation();
    const [contentDeleted, setContentDeleted] = useState("");
    const fetchContentDeleted = async () => {
        setContentDeleted(await DataStore.query(DraftContent, contentId))
    }
    useEffect(()=>{
       fetchContentDeleted()
    }, [contentId])

    const handleDelete = () => {
        console.log("EFFACER")
        try {
            if(contentId) {
                if(contentDeleted.audio) {
                    Storage.remove(contentDeleted.audio)
                }
            DataStore.delete(contentDeleted)
            }
            if(onClose)onClose()
        }
        catch (e) {
            console.log(e)
        }
    }

    //log
    useEffect(()=>{
        console.log("Content delete", contentDeleted)
    }, [contentDeleted])
    return (
        <Dialog open={true} onClose={onClose} fullWidth>
            <DialogTitle> {t('book.content.operations.delete-content')}</DialogTitle>
            <DialogContent>
                {t('book.content.operations.delete-button')}: {contentDeleted.label}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color={"secondary"} variant={"contained"}>
                    {t('generic.cancel')}
                </Button>
                <Button
                    startIcon={<Delete />}
                    variant={"contained"}

                    onClick={handleDelete}
                >
                    {t('book.content.operations.delete-button')}
                </Button>
            </DialogActions>
        </Dialog>

    )
}