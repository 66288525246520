import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {AuthorUser, Book, Draft} from "../../models";
import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import {Check, Close, Edit, Search, VisibilitySharp} from "@mui/icons-material";
import {Card, CardContent, CardHeader, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AuthorUserEdit from "./AuthorUserEdit";


export default function AuthorUserList() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    //AuthorUSer
    const [authorUsers, setAuthorUsers] = useState([]);
    const fetchAuthorUsers = async () => setAuthorUsers(await DataStore.query(AuthorUser));
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks(await DataStore.query(Book))
    const [drafts, setDrafts] = useState([]);
    const fetchDrafts = async () => setDrafts(await DataStore.query(Draft))
    useEffect(()=>{
        fetchAuthorUsers();
        fetchBooks();
        fetchDrafts();
        const obsAuthorUser = DataStore.observe(AuthorUser).subscribe(()=>fetchAuthorUsers())
        return () => {
            obsAuthorUser.unsubscribe()
        }
    }, [])

    const [edited, setEdited] = useState();
    
    //DATAGrid PARAMS
    const _renderActions = (params) => {
        return (1 || params.row === 0) && <>
            <IconButton size={"small"} onClick={()=>navigate(params.row.id)}><VisibilitySharp /></IconButton>
            <IconButton size={"small"} onClick={()=>setEdited(params.row.id)}><Edit /></IconButton>
        </>;
    }

    const _renderBoolean = (params) => {
        return params.value ? <Check /> : ""
    }

    const columns = [
        {field: '_version',             headerName: "Version",      width:40 },
        {field: 'sub',                  headerName: "Sub",          flex:1 },
        {field: 'identity',             headerName: "Identity",     flex:1, hide: false},
        {field: 'lastName',             headerName: "Nom",          flex:1 },
        {field: 'firstName',            headerName: "Prénom",       flex:1 },
        {field: 'email',                headerName: "Email",        flex:1 },
        {field: 'phone',                headerName: "Téléphone",    flex:1 },
        {field: 'books',                headerName: "Books",        width:60, renderCell: params => (books.filter(book=>book.sub === params.row.sub).length || "")},
        {field: 'drafts',               headerName: "Drafts",       width:60, renderCell: params => (drafts.filter(draft=>draft.sub === params.row.sub).length || "")},
        {field: 'accessDocumentation',  headerName: "Access Doc",   width:60, renderCell: _renderBoolean},
        {field: 'setOtherAuthor',       headerName: "All Author",   width:60, renderCell: _renderBoolean},
        {field: 'validated',            headerName: "Valid",        width:60, renderCell: _renderBoolean},
        {field: 'unsubscribe',          headerName: "Unsubscribe",  width:60, renderCell: _renderBoolean},
        {field: 'status',               headerName: "status",       width:120},
        {field: 'action',               headerName: ' ',            width: 80, renderCell: _renderActions, disableColumnMenu: true, align: 'right' }
    ]
    
    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState([]);
    
    useEffect(()=>{
        if (filter) {
            setRows(authorUsers.filter(author=>author.firstName.toLowerCase().includes(filter.toLowerCase())||author.lastName.toLowerCase().includes(filter.toLowerCase())))
        }
        else {
            setRows(authorUsers)
        }
        
    }, [authorUsers, filter])
    
    
    // const rows = authorUsers;
    
    

    return (
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>{t("authorUser.list")}</Typography> }
                action={edited}
            />
            <CardContent>
                <TextField
                    value={filter}
                    InputProps={{
                        startAdornment: <Search />,
                        endAdornment: filter !== "" && <IconButton onClick={()=>setFilter("")}><Close /></IconButton>
                    }}
                    size={"small"}
                    onChange={event=>setFilter(event.target.value)}
                />
            </CardContent>
            <CardContent>
                <DataGrid
                    density={"compact"}
                    autoHeight
                    rows={rows}
                    columns={columns}
                />
                {edited && <AuthorUserEdit authorUserId={edited} onClose={()=>setEdited(null)} />}
            </CardContent>
        </Card>
    )
}