import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DataStore} from "aws-amplify";
import {Book, BookContent, BookLike, Category, Purchase} from "../../models";
import IconButton from "@mui/material/IconButton";
import {Error, Favorite, HomeRepairService, VisibilitySharp} from "@mui/icons-material";
import {DataGrid} from "@mui/x-data-grid";
import {NavLink} from "react-router-dom";
import {Card, CardContent, CardHeader, Chip, FormControlLabel, Grid, MenuItem, Switch, TextField, Typography} from "@mui/material";
import {tdb} from "../Services/translateJSON";
import {aggregateValues} from "../Services/aggregate"
import SearchTextArea from "../Misc/SearchField";
import moment from "moment";
import FieldRange from "../Misc/FieldRange";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import {LocalizationProvider} from "@mui/lab";

export default function BooksPublished({authorIds}) {
    const {t, i18n} = useTranslation();
    
    const [books, setBooks] = useState([]);
    const fetchBooks = async () => setBooks((await DataStore.query(Book))
        .filter(book=>authorIds ? authorIds.includes(book.author.id) : true))
    
    const [contents, setContents] = useState([]);
    const fetchContents = async () => setContents((await DataStore.query(BookContent)))
    
    const [purchases, setPurchases] = useState([]);
    const fetchPurchases = async () => setPurchases((await DataStore.query(Purchase)))
    
    const [likes, setLikes] = useState([]);
    const fetchLikes = async () => setLikes((await DataStore.query(BookLike)))
    
    useEffect(()=>{
        fetchBooks();
        fetchContents();
        fetchPurchases();
        fetchLikes();
        const observeBooks = DataStore.observe(Book).subscribe(msg=>fetchBooks())
        const observeContents = DataStore.observe(BookContent).subscribe(msg=>fetchContents())
        const observePurchases = DataStore.observe(Purchase).subscribe(msg=>fetchPurchases())
        const observeLikes = DataStore.observe(BookLike).subscribe(msg=>fetchLikes())
        return ()=>{
            observeBooks.unsubscribe();
            observeContents.unsubscribe();
            observePurchases.unsubscribe();
            observeLikes.unsubscribe();
        }
    }, [])
    
    const _renderContentCount = (params) => {
        return contents
            .filter(c=>c && c.book && c.book.id === params.row.id)
            .length || ""
    }
    
    const _renderPurchasesCount = (params) => {
        return purchases
            .filter(p=>p && p.book && p.book.id === params.row.id)
            .length || ""
    }
    
    const _renderCharCount = (params) => {
        return contents
            .filter(content=>content.book && content.book.id === params.row.id)
            .reduce((total, content) => total + (content && content.text ? content.text.length : 0), 0)
    }
    
    const _renderLikesCount = (params) => {
        return likes
            .filter(l=>l && l.book && l.book.id === params.row.id)
            .length || ""
    }
    
    const _renderActions = (params) => {
        return <>
            <IconButton component={NavLink} to={'/books/published/'+params.id} size={"small"}><VisibilitySharp /></IconButton>
        </>;
    }

    const bookStatus = (params) => {
        return (
            params.row.status === "Published" ? <Chip label={t('book.status-online')} color="success" />
                : <Chip label={t('book.status-offline')} color="warning" />
        )
    }
    
    const [editMode, setEditMode] = useState(false);
    
    const _editWeight = async (params, event) => {
        console.log(params, event)
        await DataStore.save(Book.copyOf(params.row, updater => {
            updater[params.field] = parseInt(event.target.value)
        }))
    }
    
    const _renderAuthor = (params) => {
        return `${params.row.author && (params.row.author.firstName || "")} ${params.row.author && (params.row.author.lastName || "")}`
    }

    const columns = [
        {field:'_version',          headerName: "Version", width:30 },
        {field:'createdAt',         headerName: `${t('Date')}`, width:100, renderCell: params => moment(params.value).format("DD/MM/YYYY")},
        {field:'bookType',          headerName: `${t('book.type')}`,            width:80 },
        // {field:'authorUser',        headerName: `${t('book.author')}`, flex:1, renderCell: _renderAuthorUser, hide: !!authorUserId},
        {field:'author',            headerName: `${t('book.author')}`,          flex:1, renderCell: _renderAuthor},
        {field:'title',             headerName: `${t('book.title')}`,           flex:2 },
        {field:'family',            headerName: `${t('book.family')}`,          flex:2,     renderCell: params => (params.row.family   ? tdb(params.row.family.title)   : <Error />)},
        {field:'category',          headerName: `${t('book.category')}`,        flex:2,     renderCell: params => (params.row.category ? tdb(params.row.category.title) : <Error />)},
        {field: 'rating',           headerName: t('book.fields.rating'),            width:50},
        {field: 'likesCount',       headerName: t('book.fields.likes-number'),      width:50},
        {field: 'purchasedCount',   headerName: t('book.fields.purchase-number'),   width:50},
        {field: 'purchasesAmount',  headerName: t('book.fields.purchase-amount'),   width:50},
        {field: 'contents',         headerName: t('book.fields.content-number'),    width:50, renderCell: _renderContentCount },
        {field: 'charCount',        headerName: t('book.fields.chars-number'),      width:80, renderCell: _renderCharCount },
        {field:'description',       headerName: `${t('book.description')}`,     flex:2, hide: true},
        {field:'price',             headerName: `${t('book.price')}`,           width:50, align: 'right'},
        {field:'isbn',              headerName: `${t('book.isbn')}`,            flex:1, hide: true},
        {field:'status',            headerName: `${t('book.status-book')}`,     flex: 1,     renderCell: bookStatus},
        {field:'homeWeight',        headerName: `${t('book.weight.home')}`,     width:60, align: 'right', editable: editMode},
        {field:'familyWeight',      headerName: `${t('book.weight.family')}`,   width:60, align: 'right', editable: editMode},
        {field:'categoryWeight',    headerName: `${t('book.weight.category')}`, width:60, align: 'right', editable: editMode},
        {field:'action',            headerName: ' ',                            width: 80, renderCell: _renderActions, disableColumnMenu: true }
    ]
    
    // filter categories
    const [catTree, setCatTree] = useState({});
    const makeCatTree = () => {
        const _tree = {}
        books.map(draft=>{
            const _fam = draft.family;
            const _cat = draft.category;
            if (_fam && _cat) {
                if (!(_fam.id in _tree)) {
                    _tree[_fam.id] = {
                        title: _fam.title,
                        element: _fam,
                        cats: {},
                        count: 0
                    }
                }
                if (!(_cat.id in _tree[_fam.id].cats)) {
                    _tree[_fam.id].cats[_cat.id] = {
                        title: _cat.title,
                        element: _cat,
                        items: []
                    }
                }
                _tree[_fam.id].cats[_cat.id].items.push(draft);
                _tree[_fam.id].count++
            }
        })
        setCatTree(_tree)
    }
    const [famCat, setFamCat] = useState("all");
    const [famCatType, setFamCatType] = useState("");
    const [famCatId, setFamCatId] = useState("");
    useEffect(()=>{
        if (famCat === "all") {
            setFamCatType("")
            setFamCatId("")
        }
        else {
            const chunks = famCat.split("_")
            setFamCatType(chunks[0])
            setFamCatId(chunks[1])
        }
    }, [famCat])
    
    // other filters
    
    const [statuses, setStatuses] = useState({});
    const [bookTypes, setBookTypes] = useState({});
    const [categories, setCategories] = useState({});
    useEffect(()=>{
        makeCatTree()
        setStatuses(aggregateValues(books, row=>row.status));
        setBookTypes(aggregateValues(books, row=>row.bookType))
        setCategories(aggregateValues(books, row=>row.category))
    }, [books])
    
    const [rangeDates, setRangeDates] = useState([null, null]);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState("all");
    const [bookType, setBookType] = useState("all");
    const [filtered, setFiltered] = useState([]);
    useEffect(()=>{
        setFiltered(books
            .filter(d=>search ? d.title.toLowerCase().includes(search.toLowerCase()) : true)
            .filter(d=>bookType !== "all" ? d.bookType === bookType : true)
            .filter(d=>status !== "all" ? d.status === status : true)
            .filter(d=>famCatType === "fam" ? d.family && d.family.id === famCatId : true)
            .filter(d=>famCatType === "cat" ? d.category && d.category.id === famCatId : true)
            .filter(d=>(rangeDates[0]) ? (moment(d.createdAt) >= moment(rangeDates[0])) : true)
            .filter(d=>(rangeDates[1]) ? (moment(d.createdAt) <= moment(rangeDates[1])) : true)
        )
    }, [books, status, search, bookType, famCatId])

    return (
        <Card>
            <CardHeader
                title={<Typography variant={"h5"}>{t("book.publishedBook")}</Typography> }
                action={
                    <FormControlLabel value={editMode} control={<Switch />} label={'Promotion'} onChange={event=>setEditMode(event.target.value)} />
                }
            />
            <CardContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <SearchTextArea
                                variant={"outlined"}
                                callBack={value=>setSearch(value)}
                                placeholder={"Recherche"}
                                delay={500}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                select
                                onChange={event=>setFamCat(event.target.value)}
                                value={famCat}
                                fullWidth
                            >
                                <MenuItem value={"all"}>Toutes les categories</MenuItem>
                                {Object.keys(catTree).map(familyId=>[
                                    <MenuItem value={`fam_${familyId}`}>
                                        {tdb(catTree[familyId].title)}
                                        {catTree[familyId].count}
                                    </MenuItem>,
                                    ...Object.keys(catTree[familyId].cats).map(categoryId=>(
                                        <MenuItem value={`cat_${categoryId}`} sx={{paddingLeft:4}}>
                                            {tdb(catTree[familyId].cats[categoryId].title)}
                                            ({catTree[familyId].cats[categoryId].items.length})
                                        </MenuItem>
                                    ))
                                ])}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                select
                                onChange={event=>setStatus(event.target.value)}
                                value={status}
                                fullWidth
                            >
                                <MenuItem value={"all"}>Tous les statuts</MenuItem>
                                {Object.keys(statuses).map(k=><MenuItem value={k}>
                                    {t(`book.status.all.${k}`)} ({statuses[k].length})
                                </MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                size={"small"}
                                variant={"outlined"}
                                select
                                onChange={event=>setBookType(event.target.value)}
                                value={bookType}
                                fullWidth
                            >
                                <MenuItem value={"all"}>Tous les types</MenuItem>
                                {Object.keys(bookTypes).map(k=><MenuItem value={k}>
                                    {t(`${k}`)} ({bookTypes[k].length})
                                </MenuItem>)}
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <FieldRange
                                value={rangeDates}
                                setValue={setRangeDates}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {filtered.length > 0 && <DataGrid
                                density={"compact"}
                                autoHeight
                                rows={filtered}
                                columns={columns}
                                onCellEditStop={_editWeight}
                                pageSize={100}
                            />}
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    )
}